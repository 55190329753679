/* ==========================================================================
   #COMMENT
   ========================================================================== */

.c-comments {
  position: relative;
  margin-top: 60px;
}

.c-comments-heading {
  position: relative;
}

.c-comments-heading__text {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $color-dark-grey;
}

.c-comment {
  padding: 20px 0;
  font-size: 1rem;

  @include bp(medium) {
    padding: 30px 0;
    font-size: 0.9rem;
  }

  @include bp(large) {
    padding: 40px 0;
    font-size: 0.875rem;
  }

  &:not(:first-child) {
    border-top: 1px solid $color-dark-grey;
  }
}

.c-comment__author {
  font-size: 12px;
}

.c-comment__subject {
  margin-bottom: 0;
  font-size: 13px;
}

.c-comment__content {
  margin-top: 15px;

  @include bp(medium) {
    margin-top: 35px;
  }

  @include bp(large) {
    margin-top: 50px;
  }
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 30px;
  text-align: center;
  background-color: #eee;
}
