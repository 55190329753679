/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 15px;

  .u-paragraph-small-mb & {
    margin-bottom: 5px;
  }
}

address {
  font-style: normal;
}

/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: 1.4375rem;
}

ul {
  .u-list-check &,
  .u-list-bullet & {
    margin-left: 0;
    list-style-type: none;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
