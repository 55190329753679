/*
Custom classes for blocks

gray
gallery-p-lg
gallery-no-spaces
align-center
.u-video-md
.u-video-sm
*/

/* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type, max-nesting-depth */

$colors: (
  'dark-navy': $color-dark-navy,
  'turquoise': $color-green,
  'yellow': $color-yellow,
  'blue': $color-blue,
  'navy': $color-navy,
  'dark-grey': $color-dark-grey,
  'medium-grey': $color-medium-grey,
  'black': $color-black,
  'white': $color-white,
);

@each $class, $color in $colors {
  .has-#{$class}-color {
    color: $color;
  }

  .has-#{$class}-background-color {
    background-color: $color;
  }
}

.c-content-section {
  position: relative;
  clear: both;

  @include bp-down(large) {
    &--is-mobile-top-50 {
      padding-top: 50px !important;
    }

    &--is-mobile-top-30 {
      padding-top: 30px !important;
    }

    &--is-mobile-top-20 {
      padding-top: 20px !important;
    }

    &--is-mobile-bottom-50 {
      padding-bottom: 50px !important;
    }

    &--is-mobile-bottom-30 {
      padding-bottom: 30px !important;
    }

    &--is-mobile-bottom-20 {
      padding-bottom: 20px !important;
    }
  }

  &--text-white {
    * {
      color: $color-white;
    }
  }

  &--text-white .c-featured-content {
    * {
      color: inherit;
    }
  }
}

.c-content-section__inner {
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    display: table;
    content: ' ';
  }

  &::after {
    clear: both;
  }
}

.c-content-section-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-repeat: no-repeat;

  // &--streak-btm-right {
  //   background-position: bottom right;

  //   &-color-large {
  //     background-image: url('../assets/images/footer-bg-right.png');
  //   }

  //   &-color-small {
  //     background-image: url('../assets/images/streak-small-btm-right.png');
  //   }

  //   &-white-large {
  //     background-image: url('../assets/images/streak-large-white-btm-right.png');
  //   }
  // }

  // &--streak-btm-left {
  //   background-position: bottom left;

  //   &-color-large {
  //     background-image: url('../assets/images/streak-large-btm-left.png');
  //   }

  //   &-color-small {
  //     background-image: url('../assets/images/streak-small-btm-left.png');
  //   }

  //   &-white-large {
  //     background-image: url('../assets/images/courses-enquiry-left.png');
  //   }
  // }

  // &--streak-top-right {
  //   background-position: top right;

  //   &-color-large {
  //     background-image: url('../assets/images/streak-large-top-right.png');
  //   }

  //   &-color-small {
  //     background-image: url('../assets/images/streak-small-top-right.png');
  //   }

  //   &-white-large {
  //     background-image: url('../assets/images/courses-enquiry-right.png');
  //   }
  // }

  // &--streak-top-left {
  //   background-position: top left;

  //   &-color-large {
  //     background-image: url('../assets/images/home-enquiry-bg-left.png');
  //   }

  //   &-color-small {
  //     background-image: url('../assets/images/footer-bg-left.png');
  //   }

  //   &-white-large {
  //     background-image: url('../assets/images/key-bg.png');
  //   }
  // }

  // &:after {
  //   display: block;
  //   content: ' ';
  //   position: absolute;
  //   width: 1000%;
  //   top: 0;
  //   bottom: 0;
  //   left: -300%;
  //   background: inherit;
  // }
}

.google-map-block {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.c-google-map__info {
  display: none;
}

.c-block-wrapper {
  position: relative;
  padding: 0 30px;

  @include bp(medium) {
    padding: 0 15px;
  }

  .wp-block-buttons > & {
    display: inherit;
    justify-content: inherit;
  }

  .c-block-wrapper,
  .woocommerce-cart & {
    padding: 0;
  }

  .c-content-section__inner > & {
    padding: 0 30px;

    @include bp(medium) {
      padding: 0 15px;
    }
  }
}

.wp-block-columns {
  position: relative;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: calc(100% + 30px);

  @include bp-down(medium) {
    gap: 1em !important;
  }

  @include bp(large) {
    flex-wrap: nowrap;
    width: calc(100% + 50px);
  }

  @for $i from 1 through 6 {
    &.has-#{$i}-columns {
      .wp-block-column {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;

        @include bp(large) {
          width: (100 / $i) * 1%;
          max-width: (100 / $i) * 1%;
          flex-basis: (100 / $i) * 1%;
        }
      }
    }
  }

  .wp-block-column {
    position: relative;
    z-index: 2;
    margin: 0 0 15px;
    padding: 0 15px;

    @include bp(large) {
      &:not(:last-child),
      &:nth-child(odd) {
        padding-right: 25px;
      }

      &:not(:first-child),
      &:nth-child(even) {
        padding-left: 25px;
      }
    }
    // width: 100%;
    // flex-basis: 100%;

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
      margin-top: 0;
    }

    .c-block-wrapper {
      > h1,
      > h2 {
        margin-top: -18px;
      }

      > h3 {
        margin-top: -14px;
      }

      > h4 {
        margin-top: -10px;
      }

      > h5,
      > h6 {
        margin-top: -6px;
      }
    }

    .wp-block-media-text__content .c-block-wrapper {
      & > h1,
      & > h2,
      & > h3,
      & > h4,
      & > h5,
      & > h6 {
        margin-top: 0;
      }
    }

    .wp-block-image figure.alignright {
      @media (max-width: 599px) {
        float: none;
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
  }

  &.cols-equal {
    > .wp-block-column {
      > .c-block-wrapper {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }
}

// @media (min-width: 782px) {
//   .wp-block-column:not(:last-child),
//   .wp-block-column:nth-child(odd) {
//     margin-right: 45px;
//   }

//   .wp-block-column:not(:first-child),
//   .wp-block-column:nth-child(even) {
//     margin-left: 45px;
//   }
// }

.wp-block-spacer {
  clear: both;

  @include bp-down(large) {
    &.is-spacer--70 {
      height: 70px !important;
    }

    &.is-spacer--50 {
      height: 50px !important;
    }

    &.is-spacer--30 {
      height: 30px !important;
    }

    &.is-spacer--20 {
      height: 20px !important;
    }
  }
}

.wp-block-button__link {
  @include btn(true);
  @include btn-primary(true);
  padding: 1.2rem 2.5rem !important;
}

.wp-block-gallery {
  margin: 0 -15px;

  @for $i from 1 through 4 {
    &.columns-#{$i} {
      .blocks-gallery-item {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 15px;

        @include bp(small) {
          width: 50%;
          max-width: 50%;
          flex-basis: 50%;
        }

        @include bp(msmall) {
          width: (100 / $i) * 1%;
          max-width: (100 / $i) * 1%;
          flex-basis: (100 / $i) * 1%;
        }
      }
    }
  }

  .blocks-gallery-item {
    figure {
      justify-content: center;
    }
  }

  &.gallery-no-spaces {
    padding: 15px;

    .blocks-gallery-item {
      padding: 0;
    }
  }

  &.gallery-p-lg {
    margin: 0;

    .blocks-gallery-item {
      padding: 15px 0;
    }

    @include bp(small) {
      margin: 0 -50px;

      .blocks-gallery-item {
        padding: 15px 50px;
      }
    }

    @include bp(msmall) {
      margin: 0 -35px;

      .blocks-gallery-item {
        padding: 15px 35px;
      }
    }

    @include bp(medium) {
      margin: 0 -50px;

      .blocks-gallery-item {
        padding: 15px 50px;
      }
    }

    @include bp(large) {
      margin: 0 -70px;

      .blocks-gallery-item {
        padding: 15px 70px;
      }
    }
  }
}

.wp-block-media-text {
  align-items: flex-start;

  @include bp-down(medium) {
    display: block;
  }

  .wp-block-columns & {
    display: block;

    .wp-block-media-text__content {
      padding: 25px !important;
      margin-bottom: 15px;
    }
  }
}

.wp-block-media-text__content {
  @include bp-down(medium) {
    padding: 15px 10px !important;
  }
}

.wp-block-embed__wrapper {
  width: 100%;
  max-width: 600px;

  .u-video-md & {
    max-width: 850px;
  }

  .u-video-sm & {
    max-width: 350px;
  }

  .u-video-lg & {
    max-width: 100%;
  }

  .aligncenter & {
    margin-left: auto;
    margin-right: auto;
  }
}

.wp-block-separator {
  border-bottom: none;
  border-color: #bfc8d4;
}
