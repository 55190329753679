@mixin set-full-width() {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

@mixin full-vw() {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
