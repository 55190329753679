/* stylelint-disable selector-class-pattern */

.featured-content-block {
  @include full-vw();
}

.c-featured-content {
  padding: 50px 0;
  min-height: 1px;
  font-size: 1rem;
  color: $color-text;

  @include bp(medium) {
    padding: 75px 0;
  }

  > .o-wrapper__inner {
    max-width: 1100px;
  }

  h3, h4 {
    color: $color-dark-navy;
  }
}

.c-featured-content__heading {
  margin-bottom: 2rem;
}

.c-fc-item__inner {
  max-width: 400px;
  width: 100%;
  margin: 30px auto;

  @include bp(medium) {
    max-width: 510px;
  }
}

.c-fc-item__image {
  position: relative;
  overflow: hidden;
  height: 250px;

  @include bp(medium) {
    height: 280px;
  }

  @include bp(large) {
    height: 330px;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.c-featured-content__button {
  margin-top: 2rem;
}

.c-fc-item__text {
  margin-bottom: 1rem;
  color: $color-text;
}

.c-home-section--featured_content {
  > .o-wrapper__inner {
    max-width: 100%;
    padding: 0;
  }
}
