/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  &--auto {
    width: auto;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--gutless {
    width: auto;
    margin-left: -30px;
    margin-right: -30px;

    @include bp(medium) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &--gutter {
    padding: 0 10px;

    @include bp(medium) {
      padding: 0 100px;
    }
  }

  &--center {
    justify-content: center;
  }
}

.o-layout--guttered {
  justify-content: space-between;

  > .o-layout__item {
    flex: none;
  }
}

.o-layout__item {
  flex: 1;
  padding: 0 30px;
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
  box-sizing: border-box;

  @include bp(medium) {
    padding: 0 15px;
  }

  &--gutless {
    padding: 0;
  }

  &--auto {
    //flex: 1 0 auto;
    width: auto;
    flex-basis: auto;
  }

  /* stylelint-disable max-nesting-depth */

  @for $i from 1 through $columns_no {
    &--#{$i} {
      width: ((100 / $columns_no) * $i) * 1%;
      max-width: ((100 / $columns_no) * $i) * 1%;
      flex-basis: ((100 / $columns_no) * $i) * 1%;
    }
  }

  @each $name, $val in $breakpoints {
    @include bp($name) {
      @for $i from 1 through $columns_no {
        &--#{$name}-#{$i} {
          width: ((100 / $columns_no) * $i) * 1%;
          max-width: ((100 / $columns_no) * $i) * 1%;
          flex-basis: ((100 / $columns_no) * $i) * 1%;
        }
      }
    }
  }
}
