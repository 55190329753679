.fhc-block {
  position: relative;
  min-height: 150px;

  .c-loader {
    right: 0;
    left: 0;
    transform: none;
    top: 50px;
    width: 50px;
    margin: 0 auto;
  }
}

.fhc-form-content {
  opacity: 0;
}

.fhc-form-content-error {
  color: $form-error-color;
}
