.is-loggedin {
  .u-show-loggedout {
    display: none;
  }

  .u-show-loggedin {
    display: block;
  }
}

.is-loggedout {
  .u-show-loggedout {
    display: block;
  }

  .u-show-loggedin {
    display: none;
  }
}
