/* stylelint-disable */

// .c-social-links {
//   padding-top: 15px;
// }

.c-social-links__header {
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
}

.c-social-links__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  &--footer {
    justify-content: center;
  }
}

.c-social-links__item {
  margin: 0;
}

.c-social-links__link {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 5px 5px 0;

  // @include bp(large) {
  //   height: 45px;
  //   margin: 0 10px 10px 0;
  // }

  // @include bp(xlarge) {
  //   height: 50px;
  // }

  &:hover,
  &:focus {
    @each $sm_type, $sm_color in $social_icons {
      &.c-social-links__link--#{$sm_type} {
        .c-social-links--colorhover & .c-icon {
          fill: $sm_color;
        }

        .c-social-links--bghover & .c-social-links__icon {
          background-color: $sm_color;
        }
      }
    }
  }
}

.c-social-links__icon {
  display: block;
  width: 40px;
  height: 100%;
  // background-color: $color-social;
  border-radius: 50%;
  padding: 9px 9px 9px 0;
  transition: $global-transition;

  // @include bp(large) {
  //   width: 45px;
  //   padding: 10px;
  // }

  // @include bp(xlarge) {
  //   // width: 50px;
  //   // padding: 12px;
  //   width: 40px;
  //   padding: 9px;
  // }

  .c-icon {
    fill: $color-green;
    transition: $global-transition;
  }
}
