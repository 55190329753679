.c-products__tiles {
  .c-course {
    display: flex;
  }

  .c-course__box {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .o-layout__item--full .c-course__box {
    @include bp(medium) {
      max-width: 595px;
    }

    @include bp(large) {
      max-width: 50%;
    }
  }

  &--left .c-course {
    justify-content: flex-start;
  }

  &--center .c-course {
    justify-content: center;
  }

  &--right .c-course {
    justify-content: flex-end;
  }

  .c-course__price {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .c-course__meta-right {
    padding: 0;
  }
}
