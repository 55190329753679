.c-volunteer-faqs {
  // color: $color-white;
  // background-color: $color-dark-navy;
  // background-image: url('../assets/images/enquiry-bg.png');
  // background-position: left bottom;
  // background-repeat: no-repeat;

  .o-wrapper__inner {
    padding: 40px 0 80px;

    @include bp(large) {
      padding: 120px 0 180px;
    }
  }
}

// .c-volunteer-faqs__heading {
//   color: $color-white;
// }

.c-volunteer-faqs__question {
  //color: $color-blue;
  font-family: $font-body;
  font-size: 1.375rem;

  .is-type-accordion & {
    border-bottom: 1px solid $color-light-grey;
  }

  .c-accordion__icon {
    display: none;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;

    .c-icon {
      fill: inherit;
    }

    &--down {
      display: flex;
    }
  }

  &.is-active {
    .c-accordion__icon--up {
      display: flex;
    }

    .c-accordion__icon--down {
      display: none;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    appearance: none;
    padding: 10px 5px;
    margin: 0;
    background-color: transparent;
    border: none;
    color: inherit;
    font: inherit;
    text-transform: inherit;
    text-align: left;
    letter-spacing: inherit;
    cursor: pointer;
  }
}

.c-volunteer-faqs__answer {
  font-size: 1.125rem;

  .is-type-accordion &:not(.is-active) {
    display: none;
  }
}
