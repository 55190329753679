/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */

.c-comments-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > p {
    @include set-full-width();
  }

  textarea {
    max-width: 100%;
  }

  .comment-form-author,
  .comment-form-email,
  .comment-form-subject {
    width: 50%;
    max-width: 50%;
  }

  .comment-form-comment {
    order: 2;
  }

  .form-submit {
    order: 3;
    margin-top: 30px;
    text-align: right;
  }
}

.c-comments-form__heading {
  padding-bottom: 15px;
  margin-bottom: 50px;
  border-bottom: 1px solid $color-dark-grey;
}

.comment-reply-title {
  display: none;
}
