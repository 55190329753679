/* stylelint-disable max-nesting-depth */

.gallery {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;

  @include bp(medium) {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }

  @include bp(large) {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }

  @for $i from 1 through 9 {
    &.gallery-columns-#{$i} .gallery-item {
      width: 100%;

      @include bp(small) {
        width: 50%;
      }

      @if $i > 4 {
        @include bp(medium) {
          width: 100 / 3 * 1%;
        }

        @include bp(large) {
          width: 100 / 4 * 1%;
        }

        @include bp(xlarge) {
          width: 100 / $i * 1%;
        }
      }
      @else if $i > 2 {
        width: 50%;

        @include bp(medium) {
          width: 100 / $i * 1%;
        }
      }
    }
  }

  .gallery-item {
    margin: 0;
    padding: 5px;

    @include bp(medium) {
      padding: 10px;
    }

    @include bp(large) {
      padding: 15px;
    }

    img {
      position: absolute;
      top: 0;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  .gallery-icon {
    position: relative;
    display: block;
    overflow: hidden;
    height: 0;
    padding-top: 75%;
  }
}
