/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  @include btn();

  .button-link-block & {
    padding: 1.2rem 2.5rem;
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  @include btn-primary(true);
}

.c-btn--clear {
  padding: 5px 10px;
  font-size: 13px;
  margin-left: 20px;
  background-color: $color-white !important;
}

.c-btn--dynamic {
  position: relative;
  color: $color-white;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: $global-transition;
  }

  &.c-btn--text-dark {
    color: $color-navy !important;
  }

  &:hover {
    color: $color-white;
  }

  &:hover:after {
    background-color: rgba(255, 255, 255, 0.2);
  }

  span {
    position: relative;
    z-index: 2;
  }
}

.c-btn--secondary {
  @include btn-secondary();
}

.c-btn--add-to-cart {
  @include btn-secondary(true);
  padding: 0.8rem 1.5rem !important;

  .c-single-product__side-attr-list.is-select & {
    @include btn-primary(true);

    .c-add-to-cart__icon {
      display: none;
    }
  }
}

.c-btn--add-to-cart-alt {
  @include btn-primary(true);
  padding: 0.5rem 1.25rem !important;
  display: flex !important;
  align-items: center;
  font-size: 15px !important;
  line-height: 1 !important;
  border-radius: 0 !important;

  .c-add-to-cart__icon {
    width: 20px;
    height: 20px;
    fill: $color-dark-navy;
  }

  .c-single-product__side-attr-list.is-select & {
    @include btn-primary(true);

    .c-add-to-cart__icon {
      display: none;
    }
  }
}

.c-btn--back-to-cart {
  position: relative;
  top: 8px;
  font-weight: 400;

  @include bp-down(small) {
    margin-bottom: 20px;
    display: block;
  }
}

.c-btn--alt {
  @include btn();

  background-color: $color-blue;
  color: $color-white;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: lighten($color-blue, 7%);
  }
}

.c-add-to-cart__icon {
  display: block;
  width: 35px;
  height: 30px;
  margin-right: 8px;
  fill: $color-white;
}

.c-btn--center {
  justify-content: center;
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.51rem 0.8rem !important;
  font-size: 13px !important;
}

.c-btn--medium {
  padding: 0.8125rem 1rem !important;
  font-size: 0.875rem !important;
}

.c-btn--large-md {
  padding: 1.15rem 3.5rem;
}

.c-btn--large {
  padding: 1.35rem 6.45rem;
}
