.c-team-departments,
.c-team-department {
  margin-bottom: 50px;
}

.c-team-department__name {
  margin: 0;
  font-size: 1.5rem;
}

.c-team-department__link {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: solid 1px $color-light-grey;
  color: $color-green;

  &:hover,
  .is-active & {
    color: $color-blue;
  }
}

.c-team-department__icon {
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: none;
  fill: $color-green;

  .c-icon {
    width: 100%;
    height: 100%;

    .is-active &,
    .c-team-department__link:hover & {
      fill: $color-blue;
    }
  }

  &--up {
    .is-active & {
      display: flex;
    }
  }

  &--down {
    display: flex;

    .is-active & {
      display: none;
    }
  }
}

.c-team-members {
  padding: 0;
  display: none;

  .is-active & {
    display: block;
  }
}

.c-team-member {
  overflow: hidden;
  padding: 20px 10px 20px;
  border-bottom: solid 1px $color-light-grey;
  font-size: 1.125rem;

  &.has-info {
    cursor: pointer;
  }
}

.c-team-member__image {
  position: relative;
  float: left;
  margin-right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;

  @include bp(small) {
    width: 120px;
    height: 120px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.c-team-member__meta {
  overflow: hidden;
}

.c-team-member__name {
  margin: 0;
  font-size: 1.25rem;
}

.c-team-member__info {
  display: none;
  font-size: 1rem;
}

.c-team-member__info-link {
  font-size: 1rem;
  font-weight: 600;
}
