/* stylelint-disable */

.woocommerce-cart {
  .c-page-content {
    padding-top: 30px;
  }
}

.woocommerce table.shop_table {
  border: 1px solid $color-light-grey;
  margin: 0 0 24px 0;
  border-radius: 0;
}

.woocommerce-page table.cart td.actions {
  .input-text {
    width: 150px;
  }
}

// .woocommerce .cart_item {
//   .product-name {
//     .variation {
//       display: none;
//     }
//   }
// }

.woocommerce .cart_item {
  .product-name {
    .variation {
      margin: 0;
      display: inline-block;

      dt {
        display: none;
      }

      dd {
        display: flex;

        &:not(:last-child) p::after {
          content: ',';
          color: inherit;
        }
      }

      dd,
      p {
        display: inline-block;
        margin: 0;
        padding: 0;
      }
    }
  }

  &.woocommerce-cart-form__cart-item:not(.c-slide-cart__product) {
    .product-name {
      .variation {
        color: $color-green;

        &::before {
          content: ' - ';
          margin-left: 5px;
        }
      }
    }
  }
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
  float: none;
  width: 100%;
  margin: 2rem auto;

  h4 {
    font-size: 1.25rem;
  }

  table {
    font-size: 1rem;

    th {
      width: 25%;
    }
  }

  .wc-proceed-to-checkout {
    .c-btn--primary,
    .c-btn--secondary {
      @extend .c-btn--primary;
      text-transform: uppercase;
      font-weight: bold;
      min-width: 180px;
      display: inline-flex !important;
      justify-content: center;
      padding: 0.6rem 1.95rem !important;
      margin: 0;
      font-size: 13px !important;
      line-height: 1.35 !important;
    }
  }
}

.c-cart__coupons {
  padding: 9px 12px;
  margin: 2rem auto;
  border: 1px solid $color-light-grey;

  label {
    @extend h4;
    margin: 0 0 5px;
    font-size: 1.25rem;
  }

  p {
    font-size: 16px;
  }

  .c-coupon__input {
    display: flex;
    flex-direction: column;

    @include bp(medium) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .c-btn {
      display: inline-flex;
      justify-content: center;
      min-width: 180px;
      padding: 0.6rem 1.95rem !important;
      margin: 0;
    }
  }
}

.c-product-addon {
  padding: 1rem 12px;
  margin-bottom: 1rem;
  border: 1px solid $color-light-grey;
}

.c-product-addon__description {
  font-size: 16px;

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 5px;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}

.c-product-addon__info {
  .amount {
    display: block;
    padding: 0.5rem 0;
  }

  @include bp(medium) {
    display: flex;
    align-items: center;

    .amount {
      flex-grow: 1;
      padding: 0 1rem 0 0.65rem;
    }
  }

  @include bp(large) {
    .amount {
      padding: 0 1rem 0 0.75rem;
    }
  }
}

.c-product-addon__title {
  margin: 0;
  color: $color-text;

  @include bp(medium) {
    padding-right: 1rem;
  }
}

.woocommerce-cart-form .cart_item .product-name,
.c-product-addon__title {
  @include bp(medium) {
    width: 42%;
  }

  @include bp(large) {
    width: 50%;
  }

  @include bp(xlarge) {
    width: 56%;
  }
}

.c-product-addon__button {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include bp(medium) {
    width: 30%;
  }

  @include bp(large) {
    width: 25%;
  }

  @include bp-down(medium) {
    justify-content: flex-start;
  }

  .c-btn {
    min-width: 180px;
    display: inline-flex;
    justify-content: center;
    padding: 0.6rem 1.95rem !important;
    margin: 0;
    font-size: 13px !important;
    line-height: 1.35 !important;
  }
}

.cart_item.is-addon,
.woocommerce-cart-form .cart_item {
  .product-quantity {
    @include bp(medium) {
      text-align: center;
    }
  }
}

.return-to-shop {
  margin-bottom: 1.5rem;
}
