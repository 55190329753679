.ss-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}

.ss-content {
  height: 100%;
  width: calc(100% + 18px);
  padding: 0 0 0 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;

  @media (min-width: 480px) {
    .is-mac .c-single-product__side-attr-list-scroll & {
      padding-right: 18px;
    }
  }
}

// .ss-content.rtl {
//   width: calc(100% + 18px);
//   right: auto;
// }

.ss-scroll {
  position: relative;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 6px;
  border-radius: 3px;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear;

  .rfs-mini-cart.has-position-right & {
    right: 0;
    left: auto;
  }
}

.ss-hidden {
  display: none;
}

.ss-container:hover .ss-scroll,
.ss-container:active .ss-scroll {
  opacity: 1;
}

.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}