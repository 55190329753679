.c-hero {
  position: relative;
  min-height: 450px;
  align-items: center;
  overflow: hidden;

  &--small {
    min-height: 200px;
  }

  &--medium {
    min-height: 290px;
  }

  &--medium-large {
    min-height: 450px;
  }

  &--large {
    min-height: 580px;
  }

  @include bp(large) {
    min-height: 550px;

    &--small {
      min-height: 250px;
    }

    &--medium {
      min-height: 350px;
    }

    &--medium-large {
      min-height: 550px;
    }

    &--large {
      min-height: 780px;
    }
  }
}

.c-hero__image {
  position: absolute;
  inset: 0;
  object-fit: cover;
  object-position: center;
  width: 100% !important;
  height: 100% !important;
}

.c-hero__overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.c-hero__caption {
  position: relative;
  z-index: 3;
}

.c-hero__content {
  max-width: 900px;
  margin: 0 auto;
}

.c-hero__content-heading,
.c-hero__content-text {
  color: $color-navy;

  .c-hero--page &,
  &--white {
    color: $color-white;
  }

  &--white {
    a:hover {
      color: $color-yellow;
    }
  }
}

.c-hero__content-text {
  line-height: 1.6;

  .c-hero--page & {
    font-size: 1.125rem;
    font-weight: 300;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        //color: $color-green;
        text-decoration: none;
      }
    }
  }

  &--white * {
    color: $color-white;
  }

  h1 {
    @include bp-down(large) {
      font-size: 3rem !important;
    }

    @include bp-down(medium) {
      font-size: 2.5rem !important;
    }

    @include bp-down(small) {
      font-size: 2.25rem !important;
    }

    @include bp-down(400px) {
      font-size: 2rem !important;
    }

    @include bp-down(360px) {
      font-size: 1.75rem !important;
    }
  }

  h4 {
    @include bp-down(400px) {
      font-size: 1.25rem !important;
    }
  }
}

.c-hero__announcement {
  max-width: 440px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  background-color: $color-navy;

  @include bp(medium) {
    margin-top: 50px;
  }
}

.c-hero__announcement-inner {
  padding: 25px 35px 15px;

  @include bp(medium) {
    padding: 40px 60px 30px;
  }
}

.c-hero__announcement-heading {
  text-transform: uppercase;
  margin: 0 0 15px;
  color: $color-white;
  font-family: $font-body;
  font-weight: 900;
}

.c-hero__announcement-text {
  font-size: 1.125rem;
  color: $color-white;
  //line-height: 1.3;
}
