$padding:(
  'none': 0,
  'small': 15,
  'default': 30,
  'medium': 50,
  'large': 75,
  'xlarge': 90
);

.c-home-section {
  @each $name, $val in $padding {
    &.has-pt-#{$name} {
      padding-top: $val + px;
    }

    &.has-pb-#{$name} {
      padding-bottom: $val + px;
    }
  }

  @include bp-down(large) {
    &.has-pt-xlarge {
      padding-top: 60px;
    }

    &.has-pb-xlarge {
      padding-bottom: 60px;
    }

    &.has-pt-large {
      padding-top: 45px;
    }

    &.has-pb-large {
      padding-bottom: 45px;
    }

    &.has-pt-medium {
      padding-top: 40px;
    }

    &.has-pb-medium {
      padding-bottom: 40px;
    }
  }

  &.has-text-color-white {
    color: $color-white;

    h1, h2, h3, h4, h5, h6 {
      color: inherit;
    }
  }

  .o-layout-content {
    text-align: center;
    padding: 20px 0 0 0;

    h3 {
      font-size: 21px;
      font-weight: bold;
      font-family: Roboto, sans-serif;
      text-transform: capitalize;
      letter-spacing: -0.05px;
      margin-bottom: 5px;
      color: #0a2240;
    }

    p {
      font-size: 17px;
    }
  }
}

.c-home-key {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: $color-very-light-grey;
  background-image: url('../assets/images/key-bg.png');
  background-repeat: no-repeat;
  background-position: 0 -30px;
}

.c-home-key__features {
  display: flex;
  flex-wrap: wrap;
}

.c-home-key__feature {
  text-align: center;
  padding: 15px;

  @for $i from 1 through 5 {
    .has-#{$i}-columns > & {
      width: (100 / $i) * 1%;
      max-width: (100 / $i) * 1%;
      flex-basis: (100 / $i) * 1%;
    }
  }

  @include bp-down(xlarge) {
    .has-4-columns > &,
    .has-5-columns > & {
      width: 33.33333%;
      max-width: 33.33333%;
      flex-basis: 33.33333%;
    }
  }

  @include bp-down(medium) {
    .c-home-key__features > & {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.c-home-key__feature-heading {
  margin-bottom: 20px;
  font-size: 1.125rem;
  letter-spacing: normal;
  font-family: $font-body;
}

.c-home-key__feature-icon-inner {
  width: 120px;
  height: 120px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;

  @include bp-down(medium) {
    width: 100px;
    height: 100px;
    padding: 25px;
  }

  &.is-circle {
    border-radius: 50%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.c-home-key__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.125rem;
  color: $color-navy;
  font-weight: 700;

  .has-text-color-white & {
    color: $color-white;
  }

  @include bp(medium) {
    font-size: 1.25rem;
  }
}

.c-home-key__list-item-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  margin-right: 15px;
  fill: $color-navy;

  .has-text-color-white & {
    fill: $color-white;
  }

  @include bp(medium) {
    width: 35px;
    height: 35px;
  }
}

.c-home-video {
  margin-top: 30px;
  margin-bottom: 40px;
}

.c-home-extra-content {
  padding: 20px 0 30px;
}

.c-home-courses__entry,
.c-home-articles__entry-text {
  margin-bottom: 30px;
  font-size: 1.125rem;

  @include bp(large) {
    margin-bottom: 50px;
    font-size: 1.375rem;
  }
}

.c-home-article {
  padding-bottom: 15px;
  padding-top: 15px;
}

.c-home-article__box,
.c-home-article__box-inner {
  overflow: visible;

  @include bp(medium) {
    height: 100%;
  }
}

.c-home-article__box-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  background-color: $color-white;
  //border: 1px solid $color-green;

  @include bp(medium) {
    justify-content: space-between;
    padding: 10px 20px;

    .c-home-article:nth-child(3n+1) &::after,
    .c-home-article:nth-child(3n+2) &::after {
      content: '';
      display: block;
      position: absolute;
      top: 15px;
      bottom: 15px;
      right: -15px;
      width: 1px;
      border-right: 1px solid $color-light-grey;
    }
  }
}

.c-home-article__title {
  margin: 0 0 15px;
  font-weight: 600;
  //color: $color-green;
  transition: $global-transition;

  &:hover {
    color: $color-navy;
  }
}

.c-home-article__excerpt {
  margin-bottom: 35px;
  font-size: 1rem;
  color: $color-text;
}

.c-home-article__date {
  color: $color-medium-light-grey;
  text-transform: uppercase;
}

.c-home-articles__button {
  margin-top: 20px;
}
