.c-pagination {
  margin-top: 30px;
  font-size: 1rem;
  justify-content: center;
  align-items: center;

  .o-layout__item {
    flex: 0 0 auto;
  }
}

.c-pagination__page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 7px;
  color: $color-medium-grey;
  font-weight: bold;
  transition: $global-transition;

  .c-icon {
    fill: $color-link;
    width: 20px;
    height: 20px;
  }

  &:hover,
  &--current {
    color: $color-blue;
    text-decoration: none;

    .c-icon {
      fill: $color-white;
    }
  }

  @include bp(medium) {
    &--next {
      margin-left: 50px;
    }

    &--prev {
      margin-right: 50px;
    }
  }

  &--next,
  &--prev {
    padding: 5px 10px;
    border: 1px solid $color-blue;
    font-weight: 400;
    font-size: 0.875rem;
    color: $color-blue;

    &:hover {
      background-color: $color-blue;
      color: $color-white;
    }
  }
}
