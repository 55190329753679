/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

/* stylelint-disable selector-no-qualifying-type, selector-class-pattern */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-body;
  font-size: 16px; /* [1] */
  font-weight: 400;
  // line-height: 1.6; /* [1] */
  line-height: 1.5;
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  opacity: 0;

  // @include bp(medium) {
  //   font-size: 0.875rem; /* 14px */
  // }

  // @include bp(large) {
  //   font-size: 1rem; /* 16px */
  // }

  @include bp(xlarge) {
    font-size: 1.125rem; /* 18px */
  }

  &.wf-active,
  &.wf-inactive {
    opacity: 1;
  }
}

body {
  min-height: 100vh;
  width: 100%;

  &.admin-bar {
    padding-top: 32px;

    @media screen and ( max-width: 782px ) {
      padding-top: 46px;
    }
  }
}

