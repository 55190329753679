/* stylelint-disable */

.owl-stage-outer {
  position: relative;
  z-index: 2;
}

.owl-stage {
  display: flex;

  .c-testimonials__carousel & {
    display: block;
  }
}

.owl-carousel {
  position: relative;

  .owl-item {
    img {
      width: auto;
    }
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -80px;
    left: -80px;
    z-index: 99;

    @include bp-down(xxlarge) {
      display: none;
    }

    button.owl-prev,
    button.owl-next {
      position: absolute;
      top: -34px;
      width: 40px;
      height: 65px;
      fill: #b4b4b5;
    }

    button.owl-prev {
      left: 0;
      display: none;
    }

    button.owl-next {
      right: 0;
    }
  }

  &.c-testimonials__carousel,
  &.c-images-carousel {
    .owl-nav {
      right: 0;
      left: 0;
      width: 100%;
      margin: 0 auto;

      button.owl-prev,
      button.owl-next {
        display: block;

        .c-testimonials--text-white & {
          fill: $color-white;
        }
      }
    }
  }

  .owl-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    height: 40px;

    @include bp(xxlarge) {
      display: none;
    }

    .owl-dot {
      display: block;
      width: 18px;
      height: 18px;
      margin: 5px;
      border: 3px solid #a6a7a9;
      border-radius: 50%;

      .c-testimonials--text-white & {
        border-color: $color-white;
      }

      &.active {
        width: 8px;
        height: 8px;
        border-width: 4px;
      }
    }
  }
}

/* stylelint-enable */
