.c-corner-box {
  position: relative;
  width: 100%;
  overflow: hidden;

  // &:before {
  //   position: absolute;
  //   top: -20px;
  //   right: -20px;
  //   content: '';
  //   width: 40px;
  //   height: 40px;
  //   transform: rotate(45deg);
  //   background-color: rgba(255, 255, 255, 0.5);
  // }

  // &--navy:before {
  //   background-color: $color-navy;
  // }

  // &--metallic:before {
  //   background-color: $color-metallic;
  // }

  // &--very-light-grey:before {
  //   background-color: $color-very-light-grey;
  // }
}

.c-corner-box__inner {
  border: 1px solid $color-light-grey;

  &--borderless {
    border: none;
  }

  &:hover {
    border-color: $color-green;
  }
}
