.c-search-item__title {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding-bottom: 10px;
  font-weight: 400;
  border-bottom: 1px solid #d9d9d9;
}

.c-search-item__link {
  transition: $global-transition;
  color: $color-text;

  &:hover {
    color: $color-hover;
    text-decoration: none;
  }
}
