/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
}

// .c-post__date {
//   font-size: 1.25rem;
//   font-weight: 400;

//   &--small {
//     font-size: 1rem;
//   }
// }

.c-post-single__header {
  padding: 20px 0;
  background-color: $color-very-light-grey;

  @include bp(large) {
    padding: 30px 0 50px;
  }

  // .c-breadcrumbs {
  //   margin-bottom: 30px;

  //   @include bp(medium) {
  //     margin-bottom: 50px;
  //   }

  //   @include bp(large) {
  //     margin-bottom: 80px;
  //   }
  // }
}

.c-post-recent {
  padding: 40px 0;
  background-color: $color-very-light-grey;

  @include bp(large) {
    padding: 75px 0;
  }
}

.c-post-recent__header {
  @include bp-down(medium) {
    text-align: center;
  }
}

.c-post__categories,
.c-post__tags {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 1.125rem;

  a {
    color: $color-medium-grey;

    &:hover {
      color: $color-green;
    }
  }
}

.c-post__meta {
  color: $color-medium-grey;
  font-size: 1rem;
}

.c-post__content {
  padding-top: 30px;
  padding-bottom: 30px;

  @include bp(medium) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.c-post__title {
  margin: 0 0 10px;
}

.alignnone {
  margin: 1rem 1rem 1rem 0;
}

.aligncenter {
  display: block;
  margin: 1rem auto;
  text-align: center;
}

.alignright {
  display: block;
  margin: 1rem auto;
  text-align: center;

  &.button-link-block {
    @include bp-down(medium) {
      margin: 0 auto;
    }
  }

  @include bp(medium) {
    display: inline-block;
    float: right;
    margin: 1rem 0 1rem 1rem;
  }
}

.alignleft {
  display: block;
  margin: 1rem auto;
  text-align: center;

  &.button-link-block {
    @include bp-down(medium) {
      margin: 0 auto;
    }
  }

  @include bp(medium) {
    display: inline-block;
    float: left;
    margin: 1rem 1rem 1rem 0;
  }
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
