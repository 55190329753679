/* ==========================================================================
   #NORMALIZE
   ========================================================================== */

@import 'normalize-scss/sass/normalize/import-now';

mark {
  background-color: transparent;
  color: $color-green;
  font-weight: 600;
}

b, strong {
  font-weight: 900;
}
