/* stylelint-disable selector-class-pattern, selector-no-qualifying-type, selector-max-compound-selectors, max-nesting-depth */

.c-single-product__actions-top {
  margin-bottom: 30px;
}

// .c-single-product__actions {
//   @include bp-down(medium) {
//     padding-top: 30px;
//   }
// }

.c-single-product__actions-booking {
  .product.product-type-variable:not(.has-multiple-attributes) & {
    position: absolute;
    visibility: hidden;
    z-index: -9;
    opacity: 0;
  }

  .product.has-multiple-attributes & {
    .c-single-product__qty {
      position: absolute;
      visibility: hidden;
      z-index: -9;
      opacity: 0;
    }
  }
}

.c-single-product__variation-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0;

  .product.has-multiple-attributes & {
    opacity: 1;
  }

  @include bp(medium) {
    margin-bottom: 30px;
  }

  .single_variation {
    @extend .o-layout__item, .o-layout__item--medium-6;
    padding: 0;
  }
}

.c-single-product {
  .price {
    margin-bottom: 0;
    color: $color-navy;
    font-size: 1.5rem;
  }

  .stock {
    margin: 0;

    &.out-of-stock {
      color: $form-error-color;
    }

    &.in-stock {
      display: none;
    }
  }
}

.c-signle-product__book-info {
  h6 {
    font-weight: bold;
    margin-bottom: 5px;
  }

  p {
    letter-spacing: 1px;
  }

  .yellow-btn {
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    font-size: 1rem;
    line-height: 1.35;
    font-weight: 900;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 12px 20px;
    background-color: #fbc010;
    color: #0a2240;

    @include bp-down(medium) {
      margin-bottom: 20px;
    }
    // &:hover {
    //   background: #0a2240;
    //   color: #f8c010;
    // }
  }
}

.c-single-product__select-error {
  padding-top: 5px;
  color: $form-error-color;
  text-align: right;
}

.c-single-product__variations-options {
  padding-right: 30px;
}

.c-single-product__qty {
  width: 75px;
  flex-basis: 75px;
  max-width: 75px;

  .quantity .qty {
    width: 100%;
  }
}

.product-type-simple {
  form.cart {
    &,
    .simple-product-price-info {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: flex-end;
    }

    .simple-product-price-info {
      flex-wrap: nowrap;
      @include set-full-width();
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .quantity,
    .price,
    .c-btn {
      width: auto;
      flex-basis: auto;
      max-width: none;
    }

    .price {
      text-align: right;
    }

    .quantity {
      margin-right: 10px;
    }
  }
}

.c-single-course__top {
  padding-top: 30px;
  border-bottom: 1px solid $color-very-light-grey;
  background-color: #fff;

  // @include bp(large) {
  //   padding-top: 80px;
  // }
}

.c-single-course__general {
  @include bp(medium) {
    margin-bottom: 25px;
  }
}

// .c-single-course__general-inner {
//   // border-bottom: 1px solid #b6c1cf;
//   //padding-bottom: 20px;
// }

.c-single-course__title {
  margin: 0;
  //text-transform: uppercase;
  font-size: 2rem;
}

.c-single-course__entry-info {
  padding: 3rem 0;
  background-color: #f2f3f5;
}

.c-single-course__logo {
  text-align: right;

  img {
    max-height: 80px;
    width: auto;
  }

  @include bp-down(large) {
    margin-top: 30px;
    text-align: left;
  }
}

.c-single-course__info {
  margin-bottom: 0;
  font-size: 1rem;
  color: $color-green;
  font-weight: 700;

  span {
    color: $color-navy;
  }

  &--duration {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $color-medium-light-grey;
  }

  &--modules {
    font-size: 1.75rem;
    color: $color-dark-navy;
    font-weight: 400;
  }

  &--accreditation {
    margin-top: 0.5rem;
    font-weight: 400;
  }
}

.c-bundle-products {
  padding-bottom: 10px;
}

.c-bundle-products__heading {
  color: $color-navy;
  margin-bottom: 5px;
}

.c-single-product__add-to-cart {
  margin-top: 20px;

  .wc-variation-is-unavailable {
    &::after {
      content: 'Not available';
      margin: 0;
    }

    span {
      display: none;
    }
  }

  @include bp(medium) {
    margin-top: 0;
    justify-content: flex-end;

    .product.has-multiple-attributes & {
      margin-top: 20px;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      justify-content: flex-start;
    }
  }
}

// .c-single__course-dates {
//   padding-top: 10px;

//   @include bp(medium) {
//     padding-top: 0;
//   }
// }

.c-single-course__tabs-nav {
  margin-top: 20px;
  margin-bottom: -1px;
  order: 3;

  @include bp(medium) {
    margin-top: 60px;
  }
}

.c-single-course__tabs-nav-items,
.c-single-course__tabs-nav-item {
  margin: 0;
}

.c-single-course__tabs-nav-items {
  text-align: center;
  justify-content: center;

  @include bp-down(medium) {
    display: block;
  }
}

.c-single-course__tabs-nav-link {
  display: inline-block;
  padding: 0 10px;
  background-color: transparent;
  // border: 1px solid transparent;
  // text-transform: uppercase;
  font-size: 1.125rem;
  color: $color-nav;
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 50%;
    margin: 5px auto 7px;
    border-bottom: 3px solid transparent;

    @include bp(medium) {
      margin: 8px auto 0;
      border-bottom: 4px solid transparent;
    }
  }

  // @include bp(medium) {
  //   display: block;
  //   padding: 5px 10px;
  //   // border-bottom: 3px solid transparent;
  //   text-align: center;
  // }

  &:hover,
  &.is-active {
    &::after {
      border-color: $color-yellow;
    }
    // border: 1px solid $color-green;
    // background-color: $color-white;

    // @include bp(medium) {
    //   border-bottom: 3px solid $color-green;
    //   background-color: $color-white;
    // }
  }
}

.c-single-course__extra-content {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $color-very-light-grey;

  @include bp(large) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &--narrow .o-wrapper__inner {
    max-width: 750px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }
}

.c-single-course__content {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $color-white;
  line-height: 1.8;
  border-bottom: 1px solid $color-very-light-grey;

  @include bp(large) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.c-single-course__tab {
  display: none;

  &.is-active {
    display: block;
  }
}

.c-single-course__entry,
.c-single-course__tab {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin-top: 0;
  }
}

.c-single-course__entry {
  @include bp-down(medium) {
    order: 2;
  }

  &-header {
    color: $color-dark-navy;
  }

  > h3 {
    margin-top: 10px;
  }
}

.c-course-attendees {
  margin-top: 30px;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 5px 10px !important;
      margin: 0 0.5rem 0.5rem 0 !important;
      border-radius: 10px;
      list-style-type: none;
      border: 1px solid $color-lighter-grey;

      @include bp(large) {
        margin: 0 0.75rem 0.75rem 0 !important;
      }

      &::before {
        display: none !important;
      }
    }
  }
}

.c-single-course__accreditation-item {
  margin-bottom: 20px;
  border-bottom: 1px solid $color-light-grey;

  &:last-child {
    border: none;
  }
}

.c-single-course__accreditation-name {
  margin-top: 0;
  color: $color-green;
}

.c-single-course__accreditation-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-single-course__accreditation-logo {
  max-height: 70px;
  max-width: 100px;
  width: auto;
  height: auto;
  order: 2;
}

.c-single-course__faq-question {
  margin: 0 0 5px;
  font-size: 1.125rem;
  //color: $color-green;
}

.c-single-course__faq {
  margin-bottom: 20px;
}

.c-single-course__review {
  position: relative;
  margin-bottom: 30px;
  padding: 5px 0 5px 50px;

  blockquote {
    margin-bottom: 5px;
  }

  figure {
    margin: 0;
  }

  figcaption {
    font-weight: 700;
    font-size: 0.875rem;
  }
}

.c-single-course__video {
  max-width: 600px;
  margin: 0 auto 30px;
}

.c-single-course__video-description {
  padding-top: 10px;
}

.c-single-course__review-icon {
  position: absolute;
  top: 10px;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  fill: $color-navy;
}

.c-single-product__side-tabs {
  display: none;
}

.c-single__course-dates {
  @include bp(medium) {
    display: flex;
    justify-content: flex-end;
  }
}

.c-single-product__side-info {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
}

.c-single-product__side-attributes,
.c-single-product__bundles-attributes {
  width: 100%;
  flex-basis: 100%;
  //max-width: 400px;
}

.c-single-product__side-attr {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: $color-dark-navy;
  color: $color-white;

  &.has-select {
    padding: 0 0 10px;
    background-color: transparent;
  }

  @include bp(small) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @include bp(large) {
    margin-top: 0;
  }

  &.is-out-of-stock {
    padding: 0;
    margin-top: 0;
    background-color: transparent;
  }

  // &.has-select {
  //   padding-bottom: 65px;

  //   @include bp-down(small) {
  //     padding-bottom: 95px;
  //   }
  // }
}

.c-single-product__side-attr-title {
  //text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-white;

  .has-select > & {
    color: $color-text;
  }

  .is-out-of-stock > & {
    display: none;
  }
}

// .c-single-product__side-attr-list-selection {
//   display: flex;
//   align-items: center;
//   height: 54px;
//   padding: 10px 20px;
//   border-bottom: solid 1px $color-light-grey;
//   background-color: $color-very-light-grey;
//   color: $color-text;
//   font-size: 14px;
//   font-weight: 300;
//   line-height: 1;
// }

.c-single-product__side-attr-list-scroll {
  position: relative;
  overflow: hidden;

  @include bp-down(small) {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;

    .ss-scroll {
      display: none;
    }

    .is-open & {
      display: block;
    }
  }
}

.c-single-product__side-attr-list {
  position: relative;

  &.is-select {
    position: relative;
    height: auto;
    padding: 0;
    margin: 0;
    z-index: 8;
    color: $color-text;
    border: none;

    @include bp(small) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 44px;
      overflow: hidden;
    }

    &.is-open {
      height: auto;
      z-index: 9;

      .c-single-product__side-attr-list-scroll {
        max-height: 200px;

        .ss-content {
          max-height: 200px;
        }
      }
    }

    &::after {
      content: '';
      display: none;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      border-bottom: solid 1px $color-light-grey;

      @include bp(small) {
        width: 65%;
        display: block;
      }

      @include bp(large) {
        width: 70%;
      }
    }

    @include bp-down(small) {
      height: 89px;
    }
  }

  @include bp-down(small) {
    .ss-content {
      width: 100% !important;
    }
  }
}

.c-single-product__side-attr-list-wrapper {
  position: relative;
  height: auto;

  @include bp(small) {
    height: 44px;
  }
}

.c-single-product__side-attr-list-select-icon {
  position: absolute;
  top: 0;
  left: calc(70% - 30px);
  z-index: 9;
  display: block;
  width: 20px;
  height: 44px;
  cursor: pointer;

  @include bp-down(large) {
    left: calc(65% - 30px);
  }

  @include bp-down(small) {
    left: calc(100% - 25px);
    width: 15px;
  }
}

.c-single-product__side-attr-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  min-height: 29px;
  cursor: pointer;

  &.has-passed {
    color: rgba($color-text, 0.3);
  }

  .c-single-product__side-attr-list.is-select & {
    margin: 0;
    border-top: none;
    line-height: 1;
    align-items: stretch;

    @include bp-down(small) {
      flex-wrap: wrap;
    }

    .c-single-product__attr-item-meta {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      height: 44px;
      padding: 7px 20px;
      border-bottom: solid 1px $color-light-grey;
      border-right: solid 1px $color-light-grey;
      border-left: solid 1px $color-light-grey;
      background-color: $color-very-light-grey;
      box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
      transition: $global-transition;

      @include bp-down(medium) {
        padding: 7px 10px;
      }

      @include bp-down(small) {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
      }

      .amount {
        padding-left: 5px;
        text-align: right;
      }
    }

    &:hover:not(.is-selection),
    &.is-active:not(.is-selection) {
      .c-single-product__attr-item-meta {
        background-color: $color-white;
      }
    }

    .c-single-product-add-to-cart {
      span {
        white-space: nowrap;
      }
    }

    .is-not-available {
      color: $color-text;
    }

    .is-not-available,
    .c-single-product-add-to-cart {
      display: none !important;

      @include bp-down(small) {
        height: 44px;
      }
    }

    &.is-selection {
      font-weight: 700;

      .c-single-product__attr-item-meta {
        padding-right: 30px;
        background-image: url('../assets/images/angle-down-black.svg');
        background-position: center right 10px;
        background-repeat: no-repeat;
        border-top: solid 1px $color-light-grey;
      }

      .is-not-available,
      .c-single-product-add-to-cart {
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 0.3rem 0.5rem !important;
        flex-grow: 1;
      }
    }

    // &.is-active {
    //   .is-not-available,
    //   .c-single-product-add-to-cart {
    //     display: block !important;
    //   }
    // }
  }
}

.c-single-product__attr-item {
  &-meta {
    flex-basis: 70%;
    width: 70%;
    max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    white-space: normal;

    @include bp-down(large) {
      flex-basis: 65%;
      width: 65%;
      max-width: 65%;
    }

    @include bp-down(small) {
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
    }

    .amount {
      flex-wrap: nowrap;
    }
  }

  &-date,
  &-stock,
  &-meta .amount {
    // flex-basis: 33%;
    // width: 33%;
    // max-width: 33%;
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;
    padding-right: 5px;
  }

  @include bp-down(large) {
    &-date {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
    }

    &-stock,
    &-meta .amount {
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
    }
  }

  @include bp-down(small) {
    &-stock,
    &-meta .amount {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
    // flex-wrap: nowrap;

    // .amount {
    //   flex-basis: auto;
    //   width: auto;
    //   margin-left: 10px;
    // }
  }
}

// .c-single-product__attr-item-stock {
//   display: inline-block;
//   width: 100px;
//   margin-left: 20px;
// }

.c-single-product__attr-item-date {
  display: flex;
  align-items: center;

  .c-icon {
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    fill: $color-white;
    background-color: $color-blue;
    border-radius: 50%;
    padding: 1px;
    margin-left: 3px;
  }
}

.c-tooltip {
  position: relative;
}

.c-tooltip-info {
  position: absolute;
  // bottom: 35px;
  background-color: $color-blue;
  color: $color-white;
  font-size: 12px;
  padding: 5px;
  width: 100px;
  left: -42px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.3);

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateX(2px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid $color-blue;
  }

  &.is-right {
    margin-left: 9px;

    &::after {
      border-left: none;
      border-top: 5px solid transparent;
      border-right: 8px solid $color-blue;
      border-bottom: 5px solid transparent;
      right: auto;
      left: -7px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }
}

.c-single-product-add-to-cart {
  padding: 0.3rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.4 !important;
  margin: 0 !important;

  .c-add-to-cart__icon {
    width: 25px;
    height: 20px;
    margin-right: 5px;
  }

  &:after {
    font-family: WooCommerce;
    content: '\e01c';
    display: none;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: bottom;
    animation: spin 2s infinite linear;
  }

  &.is-loading:after,
  &.is-added:after {
    display: flex;
  }

  &.is-added:after {
    content: '\e017';
    animation: none;
  }
}

.c-related-courses {
  background-color: $color-white;
}

.c-related-courses__inner {
  padding-bottom: 40px;
}

.c-related-courses__heading {
  //padding-top: 30px;
  padding-bottom: 10px;
  //border-top: 1px solid #b8c2d0;
  // text-transform: uppercase;

  @include bp(large) {
    padding-top: 70px;
  }
}

.c-questions-form {
  padding-top: 10px;
}

.c-single-course__tab--contact-us > h4,
.c-questions-form {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.c-questions-form__header {
  position: relative;
  padding: 0 30px 0 0;
  text-decoration: none;
  font-size: 2.2rem;
  font-family: $font-extra;
  cursor: pointer;
  display: inline-block;

  &:after {
    position: absolute;
    right: 0;
    top: 52%;
    transform: translateY(-50%);
    display: block;
    content: '';
    width: 15px;
    height: 23px;
    background-image: url('../assets/images/angle-down-dark.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  &:hover {
    text-decoration: underline;
  }
}

.c-questions-form__inner {
  display: block;
  margin: 0 auto;

  .gfield:not(.gf_left_half):not(.gf_right_half):first-child {
    margin-top: 0;
  }
}

.c-course-boxed-info {
  margin-bottom: 30px;
  border-bottom: 1px solid $color-very-light-grey;

  & > .o-wrapper__inner {
    border-left: 1px solid $color-very-light-grey;
    margin-bottom: -1px;
  }
}

.c-course-boxed-info__box {
  border-bottom: 1px solid $color-very-light-grey;

  @include bp(medium) {
    border-right: 1px solid $color-very-light-grey;
  }
}

.c-course-boxed-info__box-inner {
  padding: 30px 0;
  height: 100%;

  @include bp(medium) {
    padding: 40px 15px;
  }
}

.c-course-boxed-info__box-image {
  margin-bottom: 20px;
  text-align: center;

  @include bp(medium) {
    margin-bottom: 30px;
  }

  img {
    max-width: 45px;
  }
}

.c-course-boxed-info__box-content {
  table {
    td, th {
      padding: 0;
      border: none;
    }
  }
}
