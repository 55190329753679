/* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type */

.c-blog__featured {
  padding: 50px 0;
  background-color: $color-very-light-grey;

  @include bp(large) {
    padding: 75px 0;
  }
}

.c-blog__featured-post,
.c-blog__featured-header {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.c-blog__featured-header {
  margin-bottom: 30px;

  @include bp-down(medium) {
    text-align: center;
  }
}

.c-blog__featured-post-content {
  @include bp(medium) {
    padding-left: 0;
  }
}

.c-blog__featured-post-inner {
  @include bp-down(medium) {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
}

.c-blog__featured-post-content-inner {
  padding: 30px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $color-white;
  border: 1px solid $color-light-grey;
  border-left: none;
  transition: $global-transition;

  .c-blog__featured-post-inner:focus &,
  .c-blog__featured-post-inner:hover & {
    border-color: $color-green;
  }

  h3 {
    font-weight: 400;
    font-size: 1.5rem;

    @include bp(medium) {
      font-size: 2rem;
    }
  }
}

.c-blog__featured-post-image {
  @include bp(medium) {
    padding-right: 0;
  }
}

.c-blog__featured-post-date,
.c-blog-post__date {
  text-transform: uppercase;
  color: $color-medium-light-grey;
  font-size: 1rem;

  .c-blog-posts__list--recent & {
    text-transform: none;
  }
}

.c-blog-posts {
  padding: 40px 0;

  @include bp(large) {
    padding: 80px 0;
  }
}

.c-blog-posts__nav ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  border-bottom: 1px solid $color-light-grey;

  a {
    display: block;
    padding: 10px 0;
    border-bottom: 5px solid transparent;
    color: $color-dark-navy;

    @include bp-down(medium) {
      padding: 10px 0 6px;
      border-bottom-width: 3px;
    }

    &:hover {
      border-color: $color-yellow;
    }
  }

  li {
    padding: 0 15px;
    margin: 0;

    @include bp(medium) {
      padding: 0 20px;
    }

    @include bp(large) {
      padding: 0 30px;
    }

    &.is-active a {
      border-color: $color-yellow;
    }
  }
}

.c-blog-posts__list {
  max-width: 1200px;
  width: 100%;
  margin: 20px auto;

  @include bp(large) {
    margin: 40px auto;
  }
}

.c-blog-post {
  padding: 15px;

  @include bp(large) {
    padding: 25px;
  }

  .c-readmore {
    font-size: 1.125rem;
  }
}

.c-blog-post__image {
  height: 215px;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}

.c-blog-post__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 350px;
  margin: 0 auto;
  background-color: $color-white;
  border: 1px solid $color-light-grey;
  transition: $global-transition;

  &.has-no-thumbnail {
    height: auto;
  }

  &:hover,
  &:focus {
    border-color: $color-green;
  }

  // .c-blog-posts__list--recent & {
  //   background-color: transparent;
  //   border: none;
  // }
}

.c-blog-post__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;

  .c-blog-posts__list--recent & {
    border: none;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 400;

    .c-blog-posts__list--recent & {
      font-weight: 600;
    }
  }
}
