.c-breadcrumbs {
  margin-bottom: 30px;
  font-size: 0.875rem;
  color: $color-blue;

  a {
    color: inherit;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
}
