/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

/* stylelint-disable */

.c-main-nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 100%;

  @include bp-down(large) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.c-main-nav__items,
.c-main-nav .mega-menu-wrap {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  width: 100%;
  //font-family: $font-headings;
  text-transform: uppercase;
  font-size: 12px;

  @include bp-down(large) {
    position: fixed !important;
    //top: calc(100% + 10px);
    top: 0;
    right: -100% !important;
    display: block !important;
    max-width: 450px !important;
    width: 95% !important;
    padding: 20px 50px !important;
    //background-color: $color-white;
    background-color: $color-dark-navy !important;
    transition: all 0.3s ease-in-out !important;
    box-shadow: 3px 5px 15px -2px rgba(0, 0, 0, 0.3) !important;
    //display: none;
    overflow: auto !important;
    z-index: 999 !important;

    &.is-open {
      right: 0 !important;
    }
  }

  @include bp-down(msmall) {
    //padding: 20px 50px;
    max-width: 400px;
  }
}

.c-main-nav .mega-menu-wrap {
  @include bp-down(large) {
    padding: 20px 40px !important;
  }
}

.c-main-nav.is-open .mega-menu-wrap {
  @include bp-down(large) {
    right: 0 !important;
  }
}

.c-main-nav__items > .menu-item {
  display: block;
  position: relative;
  font-weight: 700;
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp(large) {
    display: flex;
    margin: 0 4px;

    &:last-child {
      margin-right: 0;
    }

    &:hover .sub-menu {
      // margin-top: 0;
      // opacity: 1;
      // visibility: visible;
      display: flex;
      animation-name: dropdownSlideUp;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }

    &:hover .sub-menu .sub-menu {
      display: none;
    }
  }

  @include bp(mlarge) {
    margin: 0 15px;
  }
  &.current-menu-item {
    a {
      &:first-child {
        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          margin: 0 auto;
          content: ''!important;
          display: block!important;
          width: 30px;
          height: 4px;
          background-color: $color-yellow;
        }
      }
    }
  }
  &.current-menu-parent {
  > a {
      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: ''!important;
        display: block!important;
        width: 30px;
        height: 4px;
        background-color: $color-yellow;
      }
    }
  }
}

@keyframes dropdownSlideUp {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
}

.c-main-nav__items .menu-item a {
  position: relative;
  display: block;
  padding: 36px 0 22px;
  //color: $color-nav;
  color: $color-white;
  letter-spacing: 1px;
  text-decoration: none;
  text-align: center;
  border-bottom: 2px solid transparent;
  transition: border 0.3s ease-in-out, color 0.3s ease-in-out;

  // &.focus-visible + .c-main-nav__dropdown--level-1 {
  //   margin-top: 0;
  //   opacity: 1;
  //   visibility: visible;
  // }

  @include bp-down(large) {
    padding: 12px 0;
    font-size: 1.125rem;
    border: none;
    text-align: left;
  }

  @include bp-down(msmall) {
    font-size: 1rem;
  }
  &:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: ''!important;
      display: block!important;
      width: 30px;
      height: 4px;
      background-color: $color-yellow;

      @include bp-down(large) {
        right: auto;
      }
    }
  }
}

.c-main-nav .mega-menu-link {
  letter-spacing: 1px;
}

.c-main-nav__items .menu-item-has-children > a span {
  display: block;
  position: relative;
  // padding-right: 10px;

  // &:after {
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   right: 0;
  //   display: block;
  //   content: '';
  //   width: 0;
  //   height: 0;
  //   border-left: 4px solid transparent;
  //   border-right: 4px solid transparent;
  //   border-top: 5px solid $color-white;
  //   transition: $global-transition;

  //   @include bp-down(large) {
  //     position: relative;
  //     display: inline-block;
  //     margin-left: 10px;
  //   }
  // }
}

// .c-main-nav__items .menu-item:hover > a,
// .c-main-nav__items .current-menu-item > a,
// .c-main-nav__items .current-menu-parent > a,
// .c-main-nav__items .current-page-ancestor > a {
//   border-bottom-color: $color-yellow;
//   color: $color-white;

//   @include bp-down(large) {
//     color: $color-yellow;
//   }
// }

.c-main-nav__items .sub-menu {
  display: none;
  flex-direction: column;
  margin: 0;
  margin-top: 30px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  // transition: opacity 0.3s linear, margin 0.3s linear;

  @include bp-down(large) {
    display: none;
    width: 100%;
    position: relative;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
  li a:hover {
    &::after {
      display: none !important;
    }
  }

}

@include bp(large) {
  .c-submenu--double > .sub-menu {
    width: 460px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // .c-main-nav__items .sub-menu {
  //   border-top: 2px solid $color-yellow;
  // }

  .c-main-nav__items .sub-menu .sub-menu {
    // padding-left: 3px;
    border-top: none;
    top: 0;
    left: 100%;

    // &.c-main-nav__dropdown--reverse {
    //   padding-left: 0;
    //   padding-right: 3px;
    // }
  }

  // &:not(.c-main-nav__dropdown--level-1):not(.sub-menu) {
  //   top: 0;
  //   left: 100%;
  // }

  // &--reverse {
  //   right: 0;
  //   left: auto;
  // }

  // &--reverse:not(.c-main-nav__dropdown--level-1) {
  //   right: 100%;
  //   left: auto !important;
  // }
}

.c-main-nav__items .sub-menu .menu-item {
  position: relative;
  list-style: none;
  margin: 0;
}

@include bp(large) {
  .c-submenu--double > .sub-menu > .menu-item {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
    display: flex;
  }

  .c-submenu--double > .sub-menu > .menu-item.c-main-item--double-line {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .c-main-nav__items .sub-menu .menu-item:hover > .sub-menu {
    margin-top: 0;
    top: -1px;
    opacity: 1;
    visibility: visible;
    display: block;
  }
}

.c-main-nav__items .sub-menu .menu-item-has-children > a span {
  // display: inline-block;
  // width: 20px;
  // height: 20px;
  // float: right;
  // color: $color-nav;

  &::after {
    border-top-color: $color-nav;
  }

  @include bp-down(large) {
    display: none;
  }
}

.c-main-nav__items .sub-menu .menu-item a {
  display: block;
  padding: 12px 15px;
  color: $color-white;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  transition: $global-transition;
  background-color: transparent;

  @include bp(large) {
    background-color: $color-white;
    color: $color-nav;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-metallic;
    border-left: 1px solid $color-metallic;
    border-right: 1px solid $color-metallic;

    &:hover {
      background-color: lighten($color-metallic, 8%);
      color: $color-nav;
    }
  }

  @include bp-down(large) {
    padding: 12px 10px;
    font-size: 1.125rem;

    &:hover {
      color: $color-yellow;
    }
  }

  @include bp-down(msmall) {
    padding: 10px;
    font-size: 1rem;
  }


}


@include bp(large) {
  .c-main-nav__items .sub-menu .sub-menu .menu-item:first-child a {
    border-top: 1px solid $color-metallic;
  }

  .c-main-nav__items .sub-menu .menu-item.c-main-item--double-line a {
    border-bottom: 3px solid $color-metallic;
  }

  .c-submenu--double > .sub-menu > .menu-item a {
    align-items: flex-start;
  }
}

.c-main-nav__items .sub-menu .current-menu-item > a,
//.c-main-nav__items .current-menu-parent > a,
.c-main-nav__items .sub-menu .current-page-ancestor > a {
  background-color: lighten($color-metallic, 8%);
  color: $color-nav;

  @include bp-down(large) {
    color: $color-yellow;
    background-color: transparent;
  }
}

// .current-menu-item .c-main-nav__sublink {
//   border-bottom-color: $color-black;
// }

.c-main-nav-toggle {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  @include bp(large) {
    display: none;
  }
}

.hamburger {
  padding: 0 15px;

  &,
  &.is-active {
    &:hover {
      opacity: 1;
    }
  }
}

.hamburger-box {
  width: 44px;
  cursor: pointer;
}

.hamburger-inner {
  &,
  &:before,
  &:after {
    width: 44px;
    //background-color: $color-nav;
    background-color: $color-white;
  }

  .hamburger.is-active & {
    &,
    &:before,
    &:after {
      width: 44px;
      //background-color: $color-nav;
      background-color: $color-white;
    }
  }

  &:after {
    .hamburger:not(.is-active) & {
      width: 27px;
      left: 17px;
    }
  }
}

.mega-toggle-on > .mega-menu-link,
.mega-menu > .mega-menu-item > .mega-menu-link:hover,
.mega-menu > .mega-current-menu-item > .mega-menu-link,
.mega-menu > .mega-current-menu-parent > .mega-menu-link {
  @include bp(large) {
    position: relative;

    // .mega-indicator {
    //   display: none !important;
    // }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: '' !important;
      display: block !important;
      width: 30px;
      height: 4px;
      background-color: $color-yellow;
      // border-left: 4px solid transparent;
      // border-right: 4px solid transparent;
      // //border-top: 5px solid $color-nav;
      // border-bottom: 5px solid $color-white;
    }
  }
}
