.c-contact-page__top--right {
  padding-top: 70px;
  padding-bottom: 60px;

  &-iframe {
    background-color: #b2dbff;
  }

  &-js {
    background-color: #ededed;
  }
}

.c-contact-page__top--left {
  padding: 0;
}

.c-google-map,
.c-google-map__container {
  height: 100%;
  min-height: 350px;

  @include bp(medium) {
    min-height: 500px;
  }
}
