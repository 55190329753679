.testimonials-block {
  @include full-vw();
}

.c-testimonials {
  padding: 40px 0;
  min-height: 1px;

  @include bp(medium) {
    padding: 75px 0;
  }
}

.c-testimonials__heading {
  font-family: $font-extra;
  font-weight: 400;
  margin-bottom: 2rem;
  color: $color-dark-navy;

  .c-testimonials--text-white & {
    color: $color-white;
  }
}

.c-testimonial {
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;

  @include bp(medium) {
    font-size: 1.25rem;
  }
}

.c-testimonial__author {
  font-weight: 400;
  color: $color-text !important;

  .c-testimonials--text-white & {
    color: $color-white !important;
  }
}

.c-testimonial__inner {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

.c-testimonial__content {
  margin-bottom: 20px;
  color: $color-text !important;

  .c-testimonials--text-white & {
    color: $color-white !important;
  }

  @include bp(medium) {
    margin-bottom: 30px;
  }
}

.c-home-section--testimonials {
  > .o-wrapper__inner {
    max-width: 100%;
    padding: 0;
  }
}
