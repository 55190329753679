$spacings: (
  'none': 0,
  'xs': 5,
  'sm': 10,
  'md': 20,
  'lg': 30,
  'xlg': 40,
  'xxlg': 50
);

$sides: (
  'top',
  'bottom',
  'left',
  'right'
);

.u-no-margin {
  margin: 0;
}

.u-no-padding {
  padding: 0;
}

@each $type, $value in $spacings {
  @each $side in $sides {
    .u-p-#{$side}-#{$type} {
      padding-#{$side}: $value + unquote('px');

      @include bp-down(large) {
        @if $value > 20 {
          padding-#{$side}: ($value/2) + unquote('px');
        }
      }
    }

    .u-m-#{$side}-#{$type} {
      margin-#{$side}: $value + unquote('px');

      @include bp-down(large) {
        @if $value > 20 {
          padding-#{$side}: ($value/2) + unquote('px');
        }
      }
    }
  }
}
