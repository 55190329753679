.c-ptcs-section {
  margin-bottom: 15px;

  @include bp(medium) {
    margin-bottom: 30px;
  }
}

.c-ptcs-media-text {
  display: flex;
  flex-direction: column;

  @include bp(medium) {
    flex-direction: row;
  }
}

.c-ptcs-media-text__content {
  > :first-child {
    margin-top: 0;
  }

  @include bp(medium) {
    padding-left: 15px;

    .has-image-on-right > & {
      padding-left: 0;
      padding-right: 15px;
    }
  }

  @include bp(large) {
    padding-left: 30px;

    .has-image-on-right > & {
      padding-left: 0;
      padding-right: 30px;
    }
  }

  @include bp-down(medium) {
    width: 100% !important;
    padding: 0;
  }
}

.c-ptcs-media-text__image {
  order: -1;

  @include bp(medium) {
    padding-right: 15px;

    .has-image-on-right > & {
      order: 1;
      padding-left: 15px;
      padding-right: 0;
      text-align: right;
    }

    .has-image-center & {
      text-align: center;
    }
  }

  @include bp(large) {
    padding-right: 30px;

    .has-image-on-right > & {
      padding-left: 30px;
      padding-right: 0;
    }
  }

  @include bp-down(medium) {
    width: 100% !important;
    padding: 0 0 15px;

    .has-image-center-mobile & {
      text-align: center;
    }

    .has-image-below-mobile & {
      order: 1;
      padding: 15px 0 0;
    }
  }
}

.c-ptcs-media-text__image-inner {
  position: relative;

  .has-image-circle & {
    img {
      border-radius: 50%;
    }
  }
}

.c-single-course__tab-additional-sections {
  margin-top: 48px;
}
