.c-readmore {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  //text-transform: uppercase;
  letter-spacing: $letter-spacing;

  .c-courses--3-cols.c-courses--full-layout & {
    font-size: 1rem;
  }

  .c-fc-item & {
    font-size: 1.125rem;
  }

  svg {
    transition: $global-transition;
  }

  &--yellow {
    color: $color-yellow !important;

    &:hover {
      color: lighten($color-yellow, 10%) !important;

      svg {
        fill: lighten($color-yellow, 10%);
      }
    }
  }

  &--green {
    color: $color-green !important;

    svg {
      fill: $color-green;
    }
  }

  &--navy {
    color: $color-navy !important;

    &:hover {
      color: $color-green !important;

      svg {
        fill: $color-green;
      }
    }
  }

  &--blue {
    color: $color-blue !important;

    &:hover {
      color: $color-green !important;

      svg {
        fill: $color-green;
      }
    }
  }

  .c-course--white &:hover svg {
    fill: $color-yellow;
  }
}

.c-readmore__inner {
  align-items: center;
}

.c-readmore__text {
  color: inherit;
  transition: $global-transition;

  .c-course--white & {
    color: $color-white;

    &:hover {
      color: $color-yellow;
    }
  }
}

.c-readmore__icon {
  position: absolute;
  right: -17px;
  display: block;
  width: 18px;
  height: 18px;
  transition: $global-transition;
  opacity: 1;

  .c-readmore:hover & {
    opacity: 1;
    right: -20px;
  }

  .c-readmore--yellow & {
    fill: $color-yellow;
  }

  .c-readmore--green & {
    fill: $color-green;
  }

  .c-readmore--green:hover & {
    fill: $color-navy;
    //left: 5px;
  }

  .c-readmore--blue & {
    fill: $color-blue;
  }

  .c-readmore--navy & {
    fill: $color-navy;
  }

  .c-readmore--blue:hover &,
  .c-readmore--navy:hover & {
    fill: $color-green;
  }

  .c-course--white & {
    fill: $color-white;
  }

  .c-course--white .c-readmore:hover & {
    fill: $color-yellow;
  }
}
