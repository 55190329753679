.c-form-enquiry {
  &--home,
  &--contact,
  &--tailored,
  &.is-navy,
  &.is-blue,
  &.is-green {
    padding: 30px 0 25px;
    background-color: $color-dark-navy;
    // background-image: url('../assets/images/enquiry-bg.png');
    // background-position: left bottom;
    background-repeat: no-repeat;

    @include bp(medium) {
      padding: 50px 0 35px;
    }

    @include bp(large) {
      padding: 75px 0;
    }
  }

  .form-block & {
    padding: 30px 0 25px;

    @include bp(medium) {
      padding: 50px 0 35px;
    }

    @include bp(large) {
      padding: 75px 0;
    }
  }

  &--tailored,
  &.is-navy {
    background-color: $color-navy;
  }

  &.is-blue {
    background-color: $color-blue;
  }

  &.is-green {
    background-color: $color-green;
  }

  &.is-white {
    background-color: $color-white;
  }

  &--courses {
    padding: 20px 0 40px;
    background-color: $color-green;

    // background-image: url('../assets/images/courses-enquiry-left.png'), url('../assets/images/courses-enquiry-right.png');
    // background-position: left bottom, right top;
    background-repeat: no-repeat;

    @include bp(large) {
      padding: 80px 0 90px;
    }
  }

  &--volunteer {
    padding: 70px 0 130px;
    // background-color: $color-navy;

    // background-image: url('../assets/images/streak-large-top-right.png');
    // background-position: right top;
    background-repeat: no-repeat;


    @include bp(large) {
      padding: 70px 0 130px;
    }
  }

  &--left {
    @include bp(large) {
      padding-right: 80px;
    }

    .c-form-enquiry--courses & {
      padding-top: 15px;

      @include bp(msmall) {
        padding-top: 35px;
      }
    }
  }

  &--right {
    @include bp(large) {
      padding: 0 15px 0 40px;
    }
  }

  &.is-top &--left {
    padding: 0 15px;
  }

  &.is-top &--right {
    padding: 0 15px;
  }

  &.is-top .c-form-enquiry-inner {
    max-width: 800px;
    margin: 0 auto;
  }

  &.is-top.has-content .c-form-enquiry__form {
    &::before {
      content: '';
      display: block;
      width: 55px;
      height: 5px;
      margin: 20px auto;
      background-color: $color-yellow;
    }
  }
}

.c-form-enquiry__content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: $font-extra;
  font-weight: 400;
  font-size: 4rem;
  line-height: 0.9;
  color: $color-white;

  .c-form-enquiry.is-white & {
    color: $color-green;
  }

  @include bp(msmall) {
    margin-bottom: 30px;
    font-size: 5.125rem;
  }

  &--top {
    margin-bottom: 20px;
    text-align: center;

    @include bp(msmall) {
      margin-bottom: 30px;
    }
  }
}

.c-form-enquiry__content h3 {
  margin-top: 5px;
  color: $color-white;

  .c-form-enquiry--home,
  .c-form-enquiry.is-white & {
    color: $color-green;
  }
}

.c-form-enquiry__content {
  color: $color-white;

  .c-form-enquiry.is-white & {
    color: $color-text;
  }

  h1, h4, h5, h6 {
    color: inherit;
  }
}
