/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  //@include clearfix();
  max-width: $site-max-width;
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}

.o-wrapper__inner,
.c-block-wrapper,
.tml,
.tml-dashboard {
  position: relative;
  max-width: $container-width-medium;
  width: 100%;
  flex-basis: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @include bp(large) {
    max-width: $container-width-large;
  }

  @include bp(mlarge) {
    max-width: $container-width-max;
  }
}

// .c-block-wrapper {
//   &:before,
//   &:after {
//     content: ' ';
//     display: table;
//   }

//   &:after {
//     clear: both;
//   }
// }
