/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  position: relative;
  z-index: 991;
  padding-top: 30px;
  background-color: $color-dark-navy;
  // background-image: url('../assets/images/footer-bg-left.png'), url('../assets/images/footer-bg-right.png');
  // background-position: left top, right bottom;
  background-image: url('../assets/images/footer-bg-right.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  color: $color-white;
  font-weight: 300;
  font-size: 15px;
}

.c-footer__heading {
  margin-bottom: 15px;
  text-transform: uppercase;
  color: $color-white;
  font-weight: 600;

  @include bp(medium) {
    margin-bottom: 25px;
  }
}

// .c-quick-nav__items,
// .c-footer__bottom {
//   //font-size: 14px;
// }

.c-footer-nav__item {
  margin: 0;
  margin-right: 15px;

  @include bp-down(medium) {
    display: block;
    margin: 5px 0;
    font-weight: 700;
  }
}

.c-quick-nav__link,
.c-footer-nav__link {
  color: $color-white;

  &:hover {
    color: $color-green;
  }
}

// .c-footer__article-title {
//   color: $color-white;
//   font-weight: 400;

//   a {
//     color: inherit;

//     &:hover {
//       color: $color-green;
//     }
//   }
// }

// .c-footer__article-excerpt {
//   font-size: 13px;
//   font-weight: 300;
//   opacity: 0.9;
// }

// .c-footer__contact-details {
//   opacity: 0.9;
// }

.c-footer__bottom {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 20px;
  border-top: 1px solid #4d6078;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;

  @include bp(medium) {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 25px;
  }
}

.c-copyrights {
  opacity: 0.6;

  @include bp-down(medium) {
    padding-bottom: 10px;
  }
}

.c-footer-subscribe form {
  margin-top: -20px;
  margin-bottom: 30px;
}
