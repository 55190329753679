.c-embed {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
  }
}

