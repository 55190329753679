.c-course-speciality {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;

  @include bp(large) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  &:not(:last-child)::after {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    width: calc(100% - 30px);
    margin: 0 auto;
    left: 0;
    right: 0;
    border-bottom: 1px solid #bfc8d4;
  }
}

.c-courses-list {
  padding-top: 50px;
  padding-bottom: 40px;

  &--center {
    justify-content: center;
  }
}

.c-course-speciality__name {
  text-transform: uppercase;

  .c-courses--full-layout & {
    margin-bottom: 2rem;
  }
}

.c-course-speciality__description {
  font-size: 14px;
  font-weight: 300;

  .c-courses--3-cols.c-courses--full-layout & {
    font-size: 16px;
    margin-bottom: 2rem;
  }
}

.c-courses-carousel {
  padding: 10px 30px 10px 0;

  @include bp(small) {
    padding: 10px 40px 10px 0;
  }

  @include bp(msmall) {
    padding: 10px 50px 10px 10px;
  }

  @include bp(xxlarge) {
    padding: 10px 80px 10px 20px;
  }

  &.owl-carousel {
    .owl-nav {
      right: -20px;
      display: block;

      @include bp(small) {
        right: -10px;
      }

      @include bp(medium) {
        right: 0;
      }
    }

    .owl-dots {
      @include bp(medium) {
        display: none;
      }
    }
  }
}

.c-course__box,
.c-course__inner {
  height: 100%;
}

.c-course__box {
  position: relative;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  .c-courses--full-layout & {
    max-width: 400px;
  }

  .c-home-courses & {
    max-width: 100%;
  }

  &:before {
    transition: $global-transition;
  }

  &:hover:before {
    top: -30px;
    right: -30px;
    width: 60px;
    height: 60px;
  }
}

.c-course__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  padding: 20px 25px;
  text-decoration: none;

  .c-course--upcoming & {
    padding: 40px 50px;
  }

  .is-related > & {
    min-height: 200px;
  }

  // .c-course--default &:hover {
  //   background-color: $color-light-grey !important;
  // }
}

.c-course {
  margin-bottom: 30px;

  .c-courses-carousel & {
    margin-bottom: 0;
    height: 100%;
    padding: 1px;
  }
}

.c-course__inner,
.c-course__title {
  color: $color-white;

  &:hover {
    color: $color-white;
  }

  .c-course--dark & {
    color: $color-navy;

    &:hover {
      color: $color-navy;
    }
  }

  .c-course--default & {
    color: $color-dark-navy;

    &:hover {
      color: $color-dark-navy;
    }
  }
}

.c-course__upcoming-date {
  text-transform: uppercase;
  font-size: 12px;
  white-space: nowrap;
}

// .c-course .c-readmore__icon {
//   //fill: $color-white !important;
// }

.c-course--dark .c-readmore__icon {
  fill: $color-navy !important;
}

.c-course--default .c-readmore__icon {
  fill: $color-blue !important;
}

.c-course--dark .c-course__link,
.c-course--dark .c-readmore {
  color: inherit !important;
}

// .c-course--default {
//   .c-readmore {
//     &, &:hover, &:focus {
//       color: $color-blue !important;

//       span {
//         color: $color-blue !important;
//       }

//       svg {
//         fill: $color-blue !important;
//       }
//     }
//   }
// }

.c-course .c-readmore {
  margin-right: 10px;
}

.c-course__title {
  margin-top: 0;
  font-size: 1.125rem;

  @include bp(xlarge) {
    font-size: 1.25rem;
  }

  .c-course--upcoming &,
  .c-courses--3-cols.c-courses--full-layout & {
    font-size: 1.125rem;

    @include bp(large) {
      font-size: 1.25rem;
    }

    @include bp(xxlarge) {
      font-size: 1.5rem;
    }
  }
}

.c-course__excerpt {
  font-size: 0.9375rem;

  .c-course--upcoming &,
  .c-courses--3-cols.c-courses--full-layout & {
    font-size: 1rem;
  }
}

.c-course__meta {
  align-items: flex-end;
  margin-bottom: 15px;

  .c-course--upcoming & {
    margin-bottom: 25px;
  }
}

.c-course__meta-right {
  //padding-top: 4px;
  align-items: flex-start;
  //justify-content: flex-end;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-left: 5px;
  font-size: 14px;
  text-align: right;
}

.c-course__meta-left {
  margin-bottom: 0;
}

.c-course__type {
  font-size: 14px;
  text-transform: uppercase;
}

.c-course__speciality {
  margin-right: 3px;
  opacity: 0.8;
}

.c-course__length {
  opacity: 0.8;
  font-weight: 700;

  .c-course--default & {
    color: $color-medium-grey;
  }
}

.c-courses__load-more {
  margin-bottom: 30px;
  text-align: center;

  .c-btn {
    position: relative;
  }
}

.c-courses__error {
  text-align: center;
  color: $form-error-color;
}

.c-courses__not-found {
  padding-top: 30px;
  padding-bottom: 30px;
}

// .c-courses__not-found-heading {
//   text-transform: uppercase;
// }

.c-course-specialty__entry {
  padding-top: 2rem;
  max-width: 900px;
  margin: 0 auto;
}
