@mixin btn($important: false, $size: normal, $font-size: 1rem, $displayimp: false) {
  $imp: unquote('');
  $display_imp: unquote('');

  @if ($important) {
    $imp: unquote('!important');
  }

  @if ($displayimp) {
    $display_imp: unquote('!important');
  }

  display: inline-block $display_imp; /* [1] */
  vertical-align: middle $imp; /* [2] */
  font: inherit $imp; /* [3] */
  font-size: $font-size $imp;
  line-height: 1.35;
  font-weight: 900 $imp;
  text-align: center $imp; /* [4] */
  margin: 5px 0 $imp; /* [4] */
  cursor: pointer $imp; /* [5] */
  // padding: 0.725rem 1.25rem $imp;
  transition: $global-transition $imp;
  border-radius: 0 $imp;
  border: none $imp;
  text-transform: uppercase $imp;
  //outline: none $imp;
  letter-spacing: $letter-spacing;

  @if ($size == normal) {
    padding: 0.7rem 1.25rem $imp;
    font-size: 0.9375rem $imp;
  }
  @else if ($size == small) {
    padding: 0.51rem 0.8rem $imp;
    font-size: 13px $imp;
  }
  @else if ($size == medium) {
    padding: 0.8125rem 1rem $imp;
  }
  @else if ($size == mlarge) {
    padding: 1rem 3.5rem $imp;
  }
  @else if ($size == mlargealt) {
    padding: 0.75rem 3.5rem $imp;
  }
  @else if ($size == large) {
    padding: 1.35rem 6.5rem $imp;
  }
}

@mixin btn-primary($important: false) {
  $imp: unquote('');

  @if ($important) {
    $imp: unquote('!important');
  }

  background-color: $color-yellow $imp;
  color: $color-dark-navy $imp;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none $imp; /* [4] */
  }

  &:hover,
  &:focus {
    background-color: lighten($color-yellow, 7%) $imp;
  }
}

@mixin btn-secondary($important: false, $size: mlarge, $font_size: 1.125rem, $displayimp: true) {
  $imp: unquote('');
  $display_imp: unquote('');

  @if ($important) {
    $imp: unquote('!important');
  }

  @if ($displayimp) {
    $display_imp: unquote('!important');
  }

  @include btn($important, $size, $font-size, false);
  display: flex $display_imp;
  align-items: center;
  flex-wrap: nowrap;
  //padding: 0.85rem 1.25rem $imp;
  background-color: $color-green $imp;
  color: $color-white $imp;
  //font-size: $font_size $imp;
  font-weight: 400 $imp;
  letter-spacing: 0.5px;

  &.disabled {
    opacity: 0.6 $imp;
  }

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
  }

  &:hover,
  &:focus {
    background-color: lighten($color-green, 7%) $imp;
    color: $color-white $imp;
  }
}
