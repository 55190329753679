/* ==========================================================================
   #TABLES
   ========================================================================== */

/* stylelint-disable selector-no-qualifying-type */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table,
.c-table {
  width: 100%; /* [1] */
  border-collapse: collapse;
  margin-bottom: 1rem;
  background-color: transparent;
  border: none;

  th {
    text-align: inherit;
  }

  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid $color-light-grey;
    background-color: $color-white;
  }

  thead th {
    vertical-align: bottom;
    border-top: none;
    border-bottom: 2px solid $color-light-grey;
  }

  tbody + tbody {
    border-top: 2px solid $color-light-grey;
  }

  & & {
    background-color: $color-white;
  }

  &.first-col-nowrap {
    td:first-child {
      white-space: nowrap;
    }
  }
}

.c-table {
  &--bordered {
    border: 1px solid $color-light-grey;

    th,
    td {
      border: 1px solid $color-light-grey;
    }

    thead th,
    thead td {
      border-bottom-width: 2px;
    }
  }

  &-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
