/* stylelint-disable */
.woocommerce {
  .blockUI.blockOverlay {
    &::before {
      position: fixed !important;
    }

    &::after {
      position: fixed;
      display: block;
      content: "Processing...";
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      text-align: center;
    }
  }
}

.woocommerce form .form-row .optional {
  visibility: hidden;
}

.woocommerce form .form-row .required {
  color: $form-error-color;
}

.woocommerce-checkout {
  h3 {
    font-size: 1.5rem;
  }

  .woocommerce-additional-fields {
    > h3 {
      display: none;
    }
  }

  .woocommerce-input-wrapper {
    margin-top: 5px;
    display: block;

    .input-radio {
      display: inline-block;
      vertical-align: middle;
    }

    label {
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  .woocommerce form .form-row label,
  .woocommerce-page form .form-row label {
    display: inline-block;
    margin-right: 10px;
    line-height: 1.35;
  }

  .woocommerce form .form-row.is-conditional {
    display: none;
  }
}

.woocommerce-billing-fields,
.woocommerce-shipping-fields {
  h3 {
    text-transform: capitalize;
  }
}

.woocommerce-checkbox-group {
  .woocommerce-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px 5px 0;
  }

  .form-row.has-layout-horizontal-2-col & {
    .woocommerce-input-wrapper {
      width: 100%;

      @include bp(medium) {
        width: 50%;
      }
    }
  }

  .form-row.has-layout-horizontal-3-col & {
    .woocommerce-input-wrapper {
      width: 100%;

      @include bp(medium) {
        width: 50%;
      }

      @include bp(large) {
        width: 33.3333%;
      }
    }
  }

  .form-row.has-layout-horizontal &,
  .form-row.has-layout-horizontal-2-col &,
  .form-row.has-layout-horizontal-3-col & {
    display: flex;
    flex-wrap: wrap;
  }
}

.wc_payment_method {
  @include bp-down(small) {
    &.payment_method_paypal {
      white-space: nowrap;

      * {
        white-space: normal;
      }

      label {
        display: inline-flex;
        flex-direction: column;

        img {
          margin: 0 !important;
        }
      }
    }

    input {
      margin-right: 5px !important;
    }
  }
}

.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order {
  float: none;
  // @include bp-down(medium) {
  //   width: auto;
  //   float: right;
  // }

  // @include bp-down(small) {
  //   width: 100%;
  //   float: none;
  // }
}

.c-back-to-cart-link {
  display: block;
  margin-top: 15px;

  svg {
    width: 15px;
    height: 15px;
    fill: $color-link;
    transition: $global-transition;
  }

  &:hover {
    svg {
      fill: $color-hover;
    }
  }
}
