.c-slide-cart {
  position: fixed;
  z-index: 992;
  top: 150px;
  right: -250px;
  width: 250px;
  background-color: $color-white;
  border-left: 3px solid $color-green;
  box-shadow: 2px 0 6px -1px rgba(0, 0, 0, 0.2);
  transition: $global-transition;

  @include bp(small) {
    top: 150px;
    right: -300px;
    width: 300px;
  }

  @include bp(medium) {
    top: 150px;
    right: -370px;
    width: 370px;
  }

  &.is-open {
    right: 0 !important;
  }
}

.c-slide-cart__toggle {
  position: absolute;
  top: 0;
  left: -38px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-green;
  cursor: pointer;
  transition: $global-transition;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  @include bp(medium) {
    left: -49px;
    width: 46px;
    height: 46px;
  }

  &:hover {
    background-color: lighten($color-green, 7%);
  }
}

.c-slide-cart__toggle-icon {
  display: block;
  width: 20px;
  height: 20px;
  fill: $color-white;

  @include bp(medium) {
    width: 25px;
    height: 25px;
  }
}

.c-slide-cart__close {
  display: none;

  .c-slide-cart.is-open & {
    display: block;
  }
}

.c-slide-cart__open {
  display: block;

  .c-slide-cart.is-open & {
    display: none;
  }
}

.c-slide-cart__heading {
  padding: 10px 10px;
  border-bottom: 2px solid #e3e8ee;

  @include bp(medium) {
    padding: 20px 15px;
  }
}

.c-slide-cart__heading-text {
  margin: 0;
  text-transform: uppercase;
  font-weight: 300;
  font-family: $font-body;

  strong {
    font-weight: 300;
    color: $color-green;
  }
}

.c-slide-cart__products {
  max-height: 150px;
  overflow-x: auto;

  @include bp(medium) {
    max-height: 264px;
  }
}

.c-slide-cart__product {
  &:not(:last-child) {
    border-bottom: 1px solid #eef1f5;

    &:after {
      content: '';
      width: 100%;
      display: block;
      border-bottom: 1px solid #dae1e9;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: $color-green;
    }
  }
}

.c-slide-cart__product-inner {
  flex-flow: column;
  padding: 10px 10px;
  align-items: center;

  @include bp(medium) {
    flex-flow: row;
    padding: 10px 15px;
  }

  .o-layout__item {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.c-slide-cart__product-remove,
.c-slide-cart__product-qty {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;

  @include bp(medium) {
    width: 30px;
    max-width: 30px;
    flex-basis: 30px;
  }
}

.c-slide-cart__product-price {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;

  @include bp(medium) {
    width: 110px;
    max-width: 110px;
    flex-basis: 110px;
  }
}

.c-slide-cart__product-name {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;

  @include bp(medium) {
    width: 135px;
    max-width: 135px;
    flex-basis: 135px;
  }
}

.c-slide-cart__product-remove {
  &:after {
    display: none;
    font-family: WooCommerce;
    content: '\e01c';
    font-weight: 400;
    color: $form-error-color;
    animation: spin 2s infinite linear;
  }

  &.is-removing:after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin: 0 auto;
  }
}

.c-slide-cart__product-remove-icon {
  display: block;
  width: 18px;
  height: 18px;
  fill: $color-metallic;
  transition: $global-transition;

  @include bp-down(medium) {
    margin: 0 auto;
  }

  &:hover {
    fill: $form-error-color;
  }
}

.c-slide-cart__product-name,
.c-slide-cart__product-price {
  color: $color-medium-navy;
}

.c-slide-cart__product-name,
.c-slide-cart__product-price,
.c-slide-cart__product-qty {
  font-weight: 300;
  font-family: $font-body;

  @include bp-down(medium) {
    margin: 0;
  }
}

.c-slide-cart__product-qty {
  color: $color-metallic;
  white-space: nowrap;
}

.c-slide-cart__total {
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-top: 1px solid #eef1f5;

  @include bp(medium) {
    justify-content: space-between;
    padding: 20px 15px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    width: 100%;
    display: block;
    border-bottom: 1px solid #dae1e9;
  }

  &.o-layout--center {
    justify-content: center;
  }
}

.c-slide-cart__total-price {
  margin: 0;
  color: $color-medium-navy;

  @include bp-down(medium) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 5px;
    text-align: center;
  }
}

.c-slide-cart__total-text {
  color: #8e9aaa;
}
