/* stylelint-disable */

.select2-container {
  max-width: 100%;
  text-align: left;

  .select2-selection--single {
    height: 44px;
  }

  .select2-selection--multiple {
    cursor: pointer;
    position: relative;

    .select2-selection__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
    }

    .select2-search--inline {
      float: none;
      clear: both;
      margin-bottom: 0;
      display: none;

      .select2-search__field {
        width: 100% !important;
        margin-top: 2px;
        border: none !important;
      }
    }
  }
}

.select2-container--default {
  .select2-selection--multiple {
    border: 1px solid #c9d3df !important;
    border-radius: 0;
    min-height: 44px;

    .select2-selection__rendered {
      position: relative;
    }

    .select2-selection__clear {
      position: relative;
      z-index: 2;
      //display: none;
    }

    .selection__clear {
      width: 20px;
      position: absolute;
      z-index: 2;
      top: 10px;
      right: 22px;
      display: block;
      font-size: 18px;
      font-weight: bold;
    }

    // .select2-selection__choice {
    //   line-height: 2;
    // }
  }

  .select2-selection--single,
  .select2-selection--multiple {
    .select2-selection__arrow {
      height: 42px;
      width: 30px;
      position: absolute;
      top: 1px;
      right: 0;

      b {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        border: none;
        width: 15px;
        height: 23px;
        background-image: url('../assets/images/angle-down.svg');
        background-size: 100% auto;
      }
    }
  }

  .select2-selection--single {
    border: 1px solid #c9d3df;
    // outline: none !important;
    border-radius: 0;

    .select2-selection__rendered {
      padding: 0 0.75rem;
      line-height: 44px;
      font-size: 1rem;
      color: $color-text;
      font-weight: 400;
    }

    .select2-selection__clear {
      right: 15px;
      width: 20px;
      text-align: center;
      font-size: 18px;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #8393a7;
    color: $color-white;
  }

  .select2-results__option[aria-selected=true] {
    background-color: #8393a7;
    color: $color-white;
  }
}

.select2-dropdown {
  border: 1px solid #8393a7;
}

.select2-results__option {
  margin: 0;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  color: #8393a7;
}
