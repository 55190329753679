/* stylelint-disable */

.woocommerce-account {
  .c-page-content {
    padding: 30px 0 10px;
  }

  .woocommerce-customer-details {
    address {
      padding: 10px 15px;
      background-color: $color-white;
      border-radius: 0;
    }
  }

  .woocommerce-customer-details--phone,
  .woocommerce-customer-details--email {
    position: relative;
    margin: 5px 0 !important;

    &:before {
      top: -2px;
    }
  }

  .woocommerce-order-details__title,
  .woocommerce-column__title {
    font-size: 1.5rem;
  }

  .woocommerce-order-details__extra-fields {
    &-inner {
      padding: 10px 0;
      background-color: $color-white;
    }

    p {
      display: flex;
      margin-bottom: 5px;

      strong,
      span {
        @extend .o-layout__item, .o-layout__item--medium-6;
      }
    }
  }
}

.woocommerce-form-login {
  .woocommerce-form-login__rememberme {
    display: block !important;
    margin-bottom: 10px;
  }

  .woocommerce-form-login__submit {
    float: none !important;
  }
}

.woocommerce-MyAccount-navigation {
  ul {
    margin: 0;
    padding-right: 0;
    list-style-type: none;

    @include bp(medium) {
      padding-right: 30px;
    }
  }

  li {
    margin-bottom: 10px;
    padding-bottom: 10px;

    @include bp(medium) {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #b8c2d0;
    }

    &.woocommerce-MyAccount-navigation-link--my-membership-content {
      display: none;
    }
  }
}
