.c-accommodations {
  padding-top: 45px;
  padding-bottom: 30px;
  background-color: $color-white;

  @include bp(large) {
    padding-top: 75px;
    padding-bottom: 50px;
  }
}

.c-accommodations__entry-text {
  h1, h2, h3, h4, h5, h6 {
    color: $color-green;

    &:first-child {
      margin-top: 0;
    }
  }
}

.c-accommodation {
  margin-bottom: 30px;
  height: 100%;
}

.c-accommodation__box,
.c-accommodation__inner {
  height: 100%;
}

.c-accommodation__box {
  position: relative;

  &:before {
    transition: $global-transition;
  }

  &:hover:before {
    top: -30px;
    right: -30px;
    width: 60px;
    height: 60px;
  }
}

.c-accommodation__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  padding: 50px 35px;
}

.c-accommodation__inner,
.c-accommodation__title {
  // color: $color-white;

  // &:hover {
  //   color: $color-white;
  // }

  .c-accommodation--dark & {
    color: $color-navy;

    &:hover {
      color: $color-navy;
    }
  }
}

.c-accommodation__heading {
  margin: 0 0 120px;
  font-size: 1.875rem;
  font-weight: 700;
  font-family: $font-body;
  // color: rgba(255, 255, 255, 0.5);

  // &:hover {
  //   color: rgba(255, 255, 255, 0.5);
  // }

  strong {
    font-weight: 700;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  .c-accommodation--dark & {
    color: rgba(31, 61, 124, 0.7);

    &:hover {
      color: rgba(31, 61, 124, 0.7);
    }

    strong {
      color: $color-navy;

      &:hover {
        color: $color-navy;
      }
    }
  }
}

.c-accommodation__title {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: $font-body;
  font-weight: 400;
}

.c-accommodation__price {
  margin: 0;
  text-align: left;
  font-size: 3.5rem;
  font-weight: 700;
}
