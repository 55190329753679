/* stylelint-disable */

.woocommerce-message,
.woocommerce-error,
.woocommerce-info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top-color: $color-green;
  background-color: $color-light-grey;

  @include bp(small) {
    flex-flow: row wrap;
  }

  @include bp(medium) {
    padding: 12px 30px;
  }

  p {
    order: -1;
    margin: 0;
  }

  li {
    flex-basis: 100%;
    width: 100%;
    margin: 0;
  }

  &:before,
  &:after {
    display: none;
  }

  .button,
  .restore-item,
  .showlogin,
  .showcoupon {
    @include btn(true, small);
    @include btn-primary(true);
    float: none !important;
  }
}

.woocommerce-form-coupon {
  .button {
    @include btn(true);
    @include btn-primary(true);
  }
}

.woocommerce-form-login,
.woocommerce-account {
  .button {
    @include btn(true, small);
    @include btn-primary(true);
  }
}

.place-order {
  .button {
    //@include btn(true, large, 1rem, false);
    @include btn-secondary(true, mlargealt, 1rem, false);
  }
}

.woocommerce-checkout .woocommerce-error {
  display: block;
}

.is-add-to-cart-ajax {
  .woocommerce-message {
    display: none;
  }
}

.woocommerce-info {
  border-top-color: $color-blue;
}

.woocommerce-info-dark {
  border-top-color: $color-navy;
  background-color: $color-navy;
  color: $color-white;
}

.woocommerce-error {
  border-top-color: $form-error-color;
}

.single_add_to_cart_button {
  &:after {
    position: relative !important;
    top: auto !important;
    right: auto !important;
    margin-left: 0.53em;
    vertical-align: bottom;
  }
}

.woocommerce a.added_to_cart {
  display: none;
}

.woocommerce a.remove {
  position: relative;
  border-radius: 0;
  width: auto;
  height: auto;
  font-size: 0;

  &:hover {
    background: transparent;
  }
}

.woocommerce-form-row em {
  font-size: 0.875rem;
}

.woocommerce form .form-row {
  @include bp-down(medium) {
    float: none;
    width: 100%;
  }
}

.woocommerce form .form-row-last, .woocommerce-page form .form-row-last {
  clear: right;
}

.woocommerce form .form-row.woocommerce-validated .select2-container, .woocommerce form .form-row.woocommerce-validated input.input-text, .woocommerce form .form-row.woocommerce-validated select {
  border-color: $color-green;

  &:focus {
    border-color: $color-green;
  }
}

.woocommerce-Price-amount {
  white-space: nowrap;
}

.woocommerce-pagination {
  margin: 10px 0 30px;
}

.wc-block-grid__products {
  width: 100%;
  margin: 0 auto;

  @include bp(large) {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
}

.wc-block-grid__product {
  padding: 10px;
  text-align: left;
  border: none;
  margin: 10px auto;

  .wc-block-grid__product-title {
    margin: 15px 0 30px;
    font-family: $font-headings;
    color: $color-navy;
    font-size: 1.25rem;
  }

  .wc-block-grid__product-price {
    display: block;
    width: 100%;
    order: -1;
    margin: 0;
    font-size: 0.88rem;
    color: $color-medium-grey;
    font-weight: 700;
    opacity: 0.8;
  }

  .wp-block-button__link {
    padding: 0.75rem 1.5rem !important;
    width: 100%;
    display: block !important;
  }
}

.wc-block-grid__product-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $color-white;
  border: 1px solid $color-light-grey;
  transition: $global-transition;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .has-aligned-buttons & {
    justify-content: space-between;
  }

  &:hover {
    border-color: $color-green;
  }
}
