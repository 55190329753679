@import 'node_modules/select2/src/scss/core';
@import "node_modules/jquery-gray/css/gray";
@import 'node_modules/owl.carousel/dist/assets/owl.carousel';
@import 'node_modules/hamburgers/dist/hamburgers';
@import 'node_modules/flatpickr/dist/flatpickr';

.flatpickr-months .flatpickr-month {
  height: 32px;

  .numInput {
    border: none !important;
    background-color: transparent !important;
  }
}