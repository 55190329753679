$editorSizes: (
  'small': 0.875rem,
  'normal': 1rem,
  'medium': 1.125rem,
  'large': 1.5rem,
  'larger': 1.875rem,
  'huge': 2.25rem,
);

.u-text-nowrap {
  white-space: nowrap;
}

@each $type, $size in $editorSizes {
  .has-#{$type}-font-size {
    font-size: $size;
  }
}

.has-medium-font-size {
  font-size: 1.125rem;
}

.u-font-large {
  font-size: 1.125rem;

  @include bp(medium) {
    font-size: 1.25rem;
  }

  @include bp(large) {
    font-size: 1.375rem;
  }
}

