/* ==========================================================================
   #HEADER
   ========================================================================== */

/* stylelint-disable max-nesting-depth */

.c-header {
  padding: 5px 0;
  // z-index: 992;
  z-index: 9999;
  //background-color: #fff;
  background-color: $color-dark-navy;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.4);

  @include bp(large) {
    padding: 0;
  }

  &--left {
    width: 27%;
    max-width: 27%;
    flex-basis: 27%;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    z-index: 2;

    @include bp-down(large) {
      padding-top: 0;
      padding-bottom: 0;
      width: 80%;
      max-width: 80%;
      flex-basis: 80%;
    }
  }

  &--right {
    width: 73%;
    max-width: 73%;
    flex-basis: 73%;

    @include bp-down(large) {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }
  }
}

.c-header__inner {
  @include bp(large) {
    align-items: center;
  }
}

$logo_spacings: (
  'none': 0,
  'sm': 5,
  'md': 10,
  'lg': 15,
  'xlg': 20,
);

$logo_sides: (
  'top',
  'bottom',
  // 'left',
  // 'right'
);

.c-logo {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &--header {
    height: 60px;
  }

  &--footer {
    height: 80px;
  }

  @include bp-down(large) {
    max-width: 400px;
  }

  img {
    @include crisp-image();
    max-height: 100%;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  @each $type, $value in $logo_spacings {
    @each $side in $logo_sides {
      &--padding-#{$side}-#{$type} {
        padding-#{$side}: $value + unquote('px');

        @include bp-down(large) {
          @if $value > 10 {
            padding-#{$side}: ($value/2) + unquote('px');
          }
        }
      }
    }
  }
}
