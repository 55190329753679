.c-images-carousel {
  @include bp(xxlarge) {
    padding: 0 50px;
  }
}

.c-images-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    max-height: 100px;
  }
}

.c-images-carousel__entry {
  margin-bottom: 30px;
}
