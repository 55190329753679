/* stylelint-disable */

label,
.gform_wrapper label.gfield_label,
.gform_wrapper .gfield_label {
  display: inline-block;
  margin-bottom: 0;
  color: $color-text;
  font-weight: 300;
  font-size: 0.875rem;
  // text-transform: capitalize;

  .c-form & {
    font-weight: 400;
    color: $color-blue;
    margin-bottom: 15px;
  }

  .c-form-enquiry.is-navy .c-form &,
  .c-form-enquiry.is-blue .c-form &,
  .c-form-enquiry.is-green .c-form & {
    color: $color-white;
  }

  .c-form-enquiry--courses & {
    color: $color-form-navy;
  }

  .u-label-white &,
  .c-form-enquiry--tailored &,
  .c-form-enquiry--volunteer &,
  .c-footer-subscribe & {
    color: $color-white;
  }

  .fhc-form &,
  .c-form-enquiry.is-white & {
    color: $color-text;
  }
}

.gform_wrapper .u-label-white label.gfield_label,
.gform_heading {
  .is-navy &,
  .is-blue &,
  .is-green & {
    color: $color-white;
  }

  .fhc-form & {
    color: $color-text;
  }
}

.gform_required_legend {
  display: none;
}

.c-courses-filter label {
  color: $color-white;
  font-size: 1.125rem;
  font-weight: 400;

  @include bp-down(msmall) {
    display: block;
    text-align: left;
  }
}

button {
  border-radius: 0;
}

// button:focus {
//   outline: none;
// }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  background-color: rgba(255, 255, 255, 0) !important;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0) inset !important;
  -webkit-text-fill-color: $color-text !important;

  .c-form-enquiry:not(.is-white) & {
    -webkit-text-fill-color: $color-white !important;
  }
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.c-form__control,
textarea,
.gform_wrapper select,
.c-single-product select,
.woocommerce select,
.input-text,
.gform_wrapper textarea,
input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
  display: inline-block;
  width: 100%;
  height: 42px;
  //padding: 5px 15px 5px !important;
  padding: 10px 15px 10px !important;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.5;
  color: $color-text;
  //background-color: rgba(255, 255, 255, 0);
  background-color: $color-form-light;
  background-clip: padding-box;
  border: none;
  border: 1px solid $color-form-light;
  // border-bottom: 2px solid $color-blue;
  // border-left: 2px solid $color-blue;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .flatpickr-months & {
    padding: 0 !important;
    height: auto;
  }

  &:focus,
  &:hover {
    color: $color-text;
    //background-color: rgba(255, 255, 255, 0);
    background-color: $color-form-light;
    border-color: $color-green;
    outline: 0;
    box-shadow: none;
  }

  .c-form &,
  .is-navy & {
    color: $color-white;
    // background-color: lighten($color-dark-navy, 4%);
    // border: 1px solid lighten($color-dark-navy, 10%);
    background-color: $color-form-navy;
    border: 1px solid $color-form-navy;

    &:focus,
    &:hover {
      // background-color: lighten($color-dark-navy, 4%);
      // border-color: $color-yellow;
      background-color: $color-form-navy;
      border-color: $color-form-navy-active;
    }
  }

  // .is-navy & {
  //   background-color: lighten($color-navy, 4%);
  //   border: 1px solid lighten($color-navy, 10%);

  //   &:focus,
  //   &:hover {
  //     background-color: lighten($color-navy, 4%);
  //     border-color: $color-yellow;
  //   }
  // }

  .is-green & {
    // background-color: lighten($color-green, 4%);
    // border: 1px solid lighten($color-green, 10%);
    background-color: $color-form-green;
    border: 1px solid $color-form-green;

    &:focus,
    &:hover {
      // background-color: lighten($color-green, 4%);
      // border-color: $color-yellow;
      background-color: $color-form-green;
      border-color: $color-form-green-active;
    }
  }

  .is-blue & {
    // background-color: #3357ad;
    // border: 1px solid #2c4d9a;
    background-color: $color-form-blue;
    border: 1px solid $color-form-blue;

    &:focus,
    &:hover {
      // background-color: #3357ad;
      // border-color: $color-yellow;
      background-color: $color-form-blue;
      border-color: $color-form-blue-active;
    }
  }

  // .c-form-enquiry--tailored &,
  // .c-form-enquiry--volunteer &,
  // .u-fields-white &,
  // .c-form-enquiry--courses .u-fields-white & {
  //   //border-color: $color-white;
  // }
  .fhc-form &,
  .c-form-enquiry.is-white & {
    color: $color-text;
    background-color: $color-form-light;
    border: 1px solid $color-form-light;

    &:focus,
    &:hover {
      color: $color-text;
      background-color: $color-form-light;
      border-color: $color-green;
    }
  }

  &--file,
  &--range {
    display: block;
    width: 100%;
    border: none;
    padding: 0;
  }

  // .c-form &:focus {
  //   color: $color-white;
  // }

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &::-webkit-input-placeholder {
    color: $color-text;
    opacity: 0.8;
  }

  &::-moz-placeholder {
    color: $color-text;
    opacity: 0.8;
  }

  &:-ms-input-placeholder {
    color: $color-text;
    opacity: 0.8;
  }

  &::-ms-input-placeholder {
    color: $color-text;
    opacity: 0.8;
  }

  &::placeholder {
    color: $color-text;
    opacity: 0.8;
  }

  .c-form &,
  .c-form-enquiry.is-navy &,
  .c-form-enquiry.is-blue &,
  .c-form-enquiry.is-green & {
    &::-webkit-input-placeholder {
      color: $color-white;
      opacity: 0.8;
    }

    &::-moz-placeholder {
      color: $color-white;
      opacity: 0.8;
    }

    &:-ms-input-placeholder {
      color: $color-white;
      opacity: 0.8;
    }

    &::-ms-input-placeholder {
      color: $color-white;
      opacity: 0.8;
    }

    &::placeholder {
      color: $color-white;
      opacity: 0.8;
    }
  }

  &:disabled, &[readonly] {
    background-color: #e9ecef;
    opacity: 0.8;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }
}

input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
  .select2-selection--multiple &.select2-search__field,
  .woocommerce-checkout & {
    padding: 5px 10px !important;

    &::-webkit-input-placeholder {
      color: $color-medium-light-grey;
      font-style: normal;
      font-size: 1rem;
    }

    &::-moz-placeholder {
      color: $color-medium-light-grey;
      font-style: normal;
      font-size: 1rem;
    }

    &:-ms-input-placeholder {
      color: $color-medium-light-grey;
      font-style: normal;
      font-size: 1rem;
    }

    &::-ms-input-placeholder {
      color: $color-medium-light-grey;
      font-style: normal;
      font-size: 1rem;
    }

    &::placeholder {
      color: $color-medium-light-grey;
      font-style: normal;
      font-size: 1rem;
    }
  }
}

.input-text {
  .woocommerce-cart & {
    &#coupon_code,
    &.qty {
      width: auto !important;
    }
  }

  &.qty {
    max-width: 100px;
  }
}

.c-form__check {
  margin: 0.5rem 0;
}

select.c-form__control,
.gform_wrapper select,
.c-single-product select,
.woocommerce select {
  border: 1px solid transparent;
  padding: 4px 15px 5px !important;
  font-size: 1rem !important;

  &:focus::-ms-value {
    color: $color-text;
    background-color: $color-white;
  }

  &[size], &[multiple] {
    height: auto;
  }

  option {
    background-color: $color-light-grey;

    .c-form-enquiry & {
      background-color: $color-blue;
    }

    .is-navy & {
      background-color: $color-form-navy;
    }

    .is-green & {
      background-color: $color-form-green;
    }

    .is-blue & {
      background-color: $color-form-blue;
    }
  }
}

input[type='number'] {
  border-right: 2px solid $color-blue !important;

  &:focus {
    border-color: $color-yellow !important;
  }
}

textarea.c-form__control,
textarea {
  height: auto;
}

.c-form__group {
  margin-bottom: 1rem;
}


button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  @include btn();
  @include btn-primary();
}

.gform_wrapper .gform_footer input[type='submit'] {
  @include btn(true);
  @include btn-primary(true);

  .c-form:not(.c-footer-subscribe) & {
    @include btn(true, 'mlarge');
    @include btn-primary(true);
  }

  .c-footer-subscribe & {
    @include btn-secondary(true, normal);
    padding: 0.65rem 1.25rem !important;
    margin: 0 !important;

    @include bp(medium) {
      padding: 0.635rem 1.25rem !important;
    }

    @include bp(large) {
      padding: 0.65rem 1.25rem !important;
    }

    @include bp(xxlarge) {
      padding: 0.475rem 1.25rem !important;
    }
  }
}

.gform_wrapper form.is-form-inline {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  > div:not(.gform_body):not(.gform_footer) {
    display: none !important;
  }

  .gform_body {
    flex: 0 1 auto;
    width: 76%;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .gform_footer {
    text-align: left;
    width: 24%;
    width: auto;
    flex: 0 0 auto;
    margin: 7px 0 0;
    padding: 0;

    @include bp(medium) {
      margin: 17px 0 0;
    }

    @include bp(xxlarge) {
      margin: 17px 0 0;
    }

    .c-footer-subscribe & {
      margin: 0;
    }
  }
}

.gform_wrapper .gform_footer {
  display: flex;
  padding-top: 0;
}

.c-form-enquiry {
  .gform_wrapper .gform_footer {
    justify-content: center;

    @include bp(medium) {
      justify-content: flex-end;

      .gform_ajax_spinner {
        order: -1;
        margin-right: 10px;
      }
    }
  }

  &.is-top {
    .gform_wrapper .gform_footer {
      @include bp(medium) {
        justify-content: center;
      }
    }
  }
}

.gf-topics-levels-labels {
  display: flex;
  margin: 0;
  list-style-type: none !important;
  color: $color-white;
  font-size: 1rem;
  line-height: 1;

  @include bp(medium) {
    font-size: 1.25rem;
    align-items: flex-end;
    padding: 0 0 0 25.5%;

    &-3 {
      padding: 0 0 0 42.5%;
    }

    &-2 {
      padding: 0 0 0 51%;
    }

    &-1 {
      padding: 0 0 0 100%;
    }
  }

  p {
    width: 25%;
    flex-basis: 25%;
    max-width: 25%;
    margin: 0;
    text-align: center;
    color: $color-blue;

    // .c-form-enquiry--tailored &,
    // .c-form-enquiry--volunteer &,
    .is-navy & {
      color: $color-white;
    }
  }

  &-3 {
    p {
      width: 33%;
      flex-basis: 33%;
      max-width: 33%;
    }
  }

  &-2 {
    p {
      width: 50%;
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  &-1 {
    p {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .gf-topic-level-info {
    position: relative;
    display: block;
  }

  .gf-topic-level-icon {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin: 10px auto 10px;
    fill: $color-yellow;
    z-index: 1;
    cursor: pointer;

    @include bp(medium) {
      margin: 20px auto 0;
    }
  }

  .gf-topic-level-info-inner {
    display: block;
    // left: 50%;
    // transform: translateX(-50%);
    width: 98%;
    padding: 0;
    font-size: 13px;
    color: $color-white;
    text-align: center;
    transition: $global-transition;
    z-index: 8;

    @include bp(medium) {
      position: absolute;
      top: -15px;
      left: auto;
      right: calc(50% - 25px);
      width: 210px;
      padding: 50px 35px 35px 40px;
      visibility: hidden;
      opacity: 0;
      text-align: left;
      background-color: $color-dark-navy;
    }

    @include bp(xxlarge) {
      left: calc(50% - 25px);
      right: auto;
    }
  }

  .gf-topic-level-label {
    padding: 2px;
    display: block;
    margin: 0 auto;
    min-height: 40px;
    max-width: 135px;

    @include bp-down(medium) {
      min-height: 1px;
    }

    @include bp-down(small) {
      word-wrap: break-word;
    }
  }

  .gf-topic-level-info:hover {
    .gf-topic-level-icon {
      z-index: 9;
    }

    .gf-topic-level-info-inner {
      visibility: visible;
      opacity: 1;
    }
  }
}

.gform_wrapper {
  margin-top: 0 !important;

  .gf-section__heading {
    h5 {
      font-size: 1.125rem;
      font-family: $font-body;
      color: $color-white;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .instruction {
    padding-top: 5px;
    color: $color-white;
    font-size: 13px;
    display: none;

    .c-form-enquiry & {
      color: $color-blue;
    }
  }

  span.gform_description {
    margin: 30px 0 0;
    width: 100%;
    color: $color-blue-alt;
    font-size: 1.5625rem;
    font-weight: 400;
  }

  .gfield_description {
    color: $color-white;

    .c-form-enquiry.is-white & {
      color: $color-text;
    }

    // .c-form-enquiry--tailored &,
    // .c-form-enquiry--volunteer &,
    .is-navy & {
      padding-top: 5px;
      font-size: 13px;
    }
  }

  .gsection,
  .gsection_title,
  .gsection_description {
    color: $color-white;

    .c-form-enquiry.is-white & {
      color: $color-text;
    }
  }

  .gfield_radio {
    width: 100% !important;

    li {
      white-space: nowrap;
    }
  }

  .gf_label_noopacity .gfield_label {
    opacity: 0;

    @include bp-down(medium) {
      display: none;
    }
  }

  .gf_label_noopacity-desktop .gfield_label {
    @include bp-down(medium) {
      display: block;
      opacity: 1;
    }
  }

  .gf_choices_horizontal .gfield_radio li {
    padding: 5px !important;
    display: inline-block;

    @include bp-down(medium) {
      padding: 5px 0 !important;
      display: block;
    }
  }

  .gf_choice_label_hide .gfield_radio label {
    white-space: nowrap;

    span {
      display: none;

      @include bp-down(medium) {
        display: inline;
        margin-left: 5px;
      }

      @include bp-down(small) {
        // display: block;
        // margin-left: 0;
      }
    }
  }

  .gf_three_quarters_right,
  .gf_two_third_right,
  .gf_half_right {
    display: inline-block;
    padding-left: 0;
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .gf_three_quarters_right {
    @include bp(medium) {
      padding-left: 36px;
      width: 75%;
      flex-basis: 75%;
      max-width: 75%;
    }
  }

  .gf_two_third_right {
    @include bp(medium) {
      padding-left: 36px;
      width: 66%;
      flex-basis: 66%;
      max-width: 66%;
    }
  }

  .gf_half_right {
    @include bp(medium) {
      padding-left: 36px;
      width: 50%;
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  .gf_choices_quarter ul,
  .gf_choices_third ul,
  .gf_choices_half ul,
  .gf_choices_quarter .gfield_radio,
  .gf_choices_third .gfield_radio,
  .gf_choices_half .gfield_radio {
    display: flex;
    width: 100%;

    @include bp-down(medium) {
      display: block;
    }

    li,
    > div {
      text-align: center;

      label {
        width: auto;
      }

      @include bp-down(medium) {
        text-align: left;
        margin-bottom: 5px;
      }
    }
  }

  @include bp(medium) {
    .gf_choices_quarter ul,
    .gf_choices_quarter .gfield_radio {
      li,
      > div {
        width: 25%;
      }
    }

    .gf_choices_third ul,
    .gf_choices_third .gfield_radio {
      li,
      > div {
        width: 33%;
      }
    }

    .gf_choices_half ul,
    .gf_choices_half .gfield_radio {
      li,
      > div {
        width: 50%;
      }
    }
  }

  .ginput_container {
    position: relative;
    margin-top: 0 !important;

    .ui-datepicker-trigger {
      position: absolute;
      right: 5px;
      top: 10px;
      cursor: pointer;
    }
  }

  textarea.small {
    height: 95px;
  }

  ul.gform_fields li.gfield:not(.gf_left_half) {
    padding-right: 0 !important;
  }

  li.gfield {
    .c-form & {
      margin-bottom: 10px !important;
      margin-top: 10px !important;

      @include bp-down(medium) {
        margin-bottom: 10px !important;
        margin-top: 0 !important;
      }
    }

    &.u-no-margin {
      margin: 0 !important;
    }

    &.gf_width_half {
      @include bp(medium) {
        max-width: 50%;
      }
    }

    &.gf_width_third {
      @include bp(medium) {
        max-width: 33%;
      }
    }

    &.gf_width_quarter {
      @include bp(medium) {
        max-width: 25%;
      }
    }

    &.gf_clear {
      clear: both !important;

      &:not([style^="display"]) {
        display: block !important;
      }
    }
  }

  li.gf_first_quarter,
  li.gf_second_quarter,
  li.gf_third_quarter,
  li.gf_fourth_quarter,
  li.gf_left_third,
  li.gf_middle_third,
  li.gf_right_third {
    @include bp-down(medium) {
      width: 100% !important;
      width: 100% !important;
    }
  }

  li.gfield.gf_right_half,
  li.gfield.gf_left_half {
    max-width: 100% !important;
    width: 100% !important;
    box-sizing: border-box !important;

    @include bp(medium) {
      max-width: 50% !important;
      width: 50% !important;
    }
  }

  li.gfield.gf_left_half {
    padding-right: 0 !important;

    .c-form & {
      padding-right: 0 !important;
    }

    @include bp(medium) {
      padding-right: 16px !important;

      .c-form & {
        padding-right: 36px !important;
      }
    }
  }

  li.gfield.gf_right_half {
    padding-left: 0 !important;

    .c-form & {
      padding-left: 0 !important;
    }

    @include bp(medium) {
      padding-left: 16px !important;

      .c-form & {
        padding-left: 36px !important;
      }
    }
  }

  .gfield_error {
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
  }

  .validation_error,
  .gform_validation_errors {
    padding: 1rem !important;
    margin: 0 0 30px !important;
    background-color: $color-white !important;
    color: $form-error-color !important;
    border: 1px solid $form-error-color !important;
    font-weight: 400 !important;
    font-size: 0.9375rem !important;
    box-shadow: none !important;

    > h2 {
      color: $form-error-color !important;
      font-size: 0.9375rem !important;
      line-height: 1.25 !important;
      font-weight: 400 !important;
      padding: 0 25px !important;
    }
  }

  .gfield_required {
    color: $form-error-color;
  }

  li.gfield.gfield_error.gfield_contains_required div.ginput_container,
  li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin: 0 !important;

    .c-form & {
      margin-bottom: 15px !important;
    }
  }

  li.gfield.gfield_error.gfield_contains_required div.gfield_description.validation_message,
  .gfield_validation_message,
  .validation_message {
    padding: 1px 5px !important;
    color: $form-error-color !important;
    font-weight: 400;
    background-color: #fff;
    display: inline-block;
    width: auto;
    border-radius: 2px;
    border: none;
  }

  .gfield_error .gfield_label {
    color: $color-text !important;

    .u-label-white & {
      color: $color-white !important;
    }

    .c-form & {
      margin-bottom: 15px !important;
      color: $color-white !important;
    }

    .is-green &,
    .is-blue & {
      color: $color-white !important;
    }

    .fhc-form &,
    .c-form-enquiry.is-white & {
      color: $color-text !important;
    }
  }

  li.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
  li.gfield_error textarea {
    // border: none !important;
    // border-bottom: 2px solid $color-blue !important;
    // border-left: 2px solid $color-blue !important;

    .c-form & {
      border-color: lighten($color-dark-navy, 10%) !important;
    }

    .is-navy & {
      border-color: lighten($color-navy, 10%) !important;
    }

    .is-blue & {
      border-color: #2c4d9a !important;
    }

    .is-green & {
      border-color: lighten($color-green, 10%) !important;
    }
  }
}

.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label {
  padding-top: 2px;
  font-size: 14px;
  line-height: 18px;
}

.gform_wrapper .gfield_checkbox .gchoice,
.gform_wrapper .gfield_radio .gchoice {
  margin-bottom: 8px;
}

input[type=radio],
input[type=checkbox] {
  display: none !important;

  .woocommerce-checkout & {
    display: inline !important;
  }
}

input[type=radio] + label,
input[type=checkbox] + label,
.woocommerce-form__label-for-checkbox {
  margin: 0;
  position: relative;
  cursor: pointer;
}

label {
  input[type=radio],
  input[type=checkbox] {
    display: inline-block !important;
  }
}

input[type=radio] + label:before,
input[type=checkbox] + label:before {
  position: relative;
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: -2px 20px 0 0;
  vertical-align:middle;
  cursor:pointer;
  //border-radius:  50%;
  // border: 1px solid lighten($color-dark-navy, 10%);
  // background-color: lighten($color-dark-navy, 4%);
  // border: 1px solid $color-form-navy-active;
  // background-color: $color-form-navy;
  border: 1px solid $color-form-light;
  background-color: $color-form-light;

  .is-navy & {
    // border: 1px solid lighten($color-navy, 10%);
    // background-color: lighten($color-navy, 4%);
    border: 1px solid $color-form-navy-active;
    background-color: $color-form-navy;
  }

  .is-green & {
    // border: 1px solid lighten($color-green, 10%);
    // background-color: lighten($color-green, 4%);
    border: 1px solid $color-form-green-active;
    background-color: $color-form-green;
  }

  .is-blue & {
    // border: 1px solid #2c4d9a;
    // background-color: #3357ad;
    border: 1px solid $color-form-blue-active;
    background-color: $color-form-blue;
  }

  .fhc-form &,
  .c-questions-form &,
  .c-form-enquiry.is-white & {
    border: 1px solid $color-form-light;
    background-color: $color-form-light;
  }

  .woocommerce-checkout & {
    display: none;
  }
}

.gf_choice_label_hide input[type=radio] + label:before,
.gf_choice_label_hide input[type=checkbox] + label:before {
  margin-right: 0;
}

input[type=radio]:checked + label,
input[type=checkbox]:checked + label {
  position: relative;
  font-weight: 400 !important;

  &:after {
    position: absolute;
    top: 5px;
    height: 15px;
    left: 5px;
    content: '\2715';
    display: block;
    font-size: 15px;
    font-weight: 700;
    //color: $color-white;
    color: $color-black;
    z-index: 2;
    line-height: 15px;

    .wc_payment_method & {
      top: 50%;
      transform: translateY(-50%);
    }

    .c-form-enquiry & {
      color: $color-white;
    }

    .fhc-form &,
    .c-questions-form &,
    .c-form-enquiry.is-white & {
      color: $color-black;
    }

    .woocommerce-checkout & {
      display: none;
    }
  }
}

.gform_confirmation_message {
  color: $color-green;
  font-size: 1.125rem;
  background-color: $color-white;
  text-align: center;
  padding: 1rem;
  border: solid 1px $color-green;
}


/* General GF Date Picker */
.ui-datepicker {
  background: $color-yellow;
  border: 2px solid $color-yellow;
  border-radius: 0;
  display:none;
  padding: 10px;
  width: 250px;
  font-family: $font-body;

  .ui-datepicker-header {
    background: $color-blue-alt;
    border: none;
    box-shadow: none;

    .ui-datepicker-month,
    .ui-datepicker-year {
      margin-top: 0;
      border: 1px solid $color-blue-alt;
      padding: 3px 5px;
    }
  }

  .ui-datepicker-calendar {
    background: $color-yellow;
  }

  table {
    width: 100%;

    td {
      border: 1px solid $color-blue-alt !important;
      background: $color-yellow !important;
      transition: $global-transition;
      text-align: center;

      a {
        display: block;
        padding: 5px;
        line-height: 22px;
        font-size: 12px;
        background: $color-yellow !important;
        text-shadow: none;
        color: $color-text;
        box-shadow: none !important;

        &.ui-state-active {
          border: none;
        }
      }

      &:hover,
      &.ui-datepicker-current-day {
        &,
        a {
          background: $color-blue !important;
          color: $color-white;
        }
      }

      &.ui-state-disabled,
      &.ui-datepicker-unselectable {
        background-color: lighten($color-yellow, 10%) !important;
      }

      &.ui-datepicker-today {
        &,
        a {
          background-color: darken($color-yellow, 10%) !important;
          color: $color-text;

          &:hover {
            background: $color-blue !important;
            color: $color-white;
          }
        }
      }
    }

    th {
      text-shadow: none;
      text-align: center;
      border: 1px solid $color-blue-alt !important;
      font-family: $font-headings;
      color: $color-text;

      span {
        font-weight: 700 !important;
        font-size: 12px;
      }
    }
  }
}

.c-form-pardot-confirmation {
  margin-bottom: 30px;
}
