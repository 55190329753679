/* stylelint-disable max-nesting-depth, selector-max-compound-selectors, selector-no-qualifying-type */

.c-page {
  .woocommerce-cart &,
  .woocommerce-checkout &,
  .woocommerce-account & {
    font-size: 1.125rem;
  }
}

.c-page-content {
  //padding: 30px 0 0;
  padding: 0;

  // @include bp(large) {
  //   padding: 35px 0 0;
  // }

  h1, h2, h3, h4, h5, h6 {
    //font-weight: 400;
    // font-family: $font-body;
    // color: $color-text;
    line-height: 1.5;

    strong {
      font-weight: 700;
    }
  }
}

.c-page-content__inner {
  padding-left: 0;
  padding-right: 0;

  .is-checkout & {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.c-page__title {
  margin-top: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;

  @include bp(large) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  &--light {
    color: $color-white;
  }

  .c-courses-calendar-section & {
    padding: 10px 0 30px;
  }
}

.table-credentialing {
  table {
    tr {
      @include bp-down(medium) {
        &.d-md-none {
          display: none;
        }

        &:first-child {
          display: none;
        }
      }

      td {
        width: 30%;

        .d-lg-none {
          display: none;
        }

        @include bp-down(medium) {
          width: 100%;
          display: block;

          .d-lg-none {
            display: block;
          }
        }
      }

      td:first-child {
        width: 40%;

        .d-lg-none {
          display: none;
        }

        @include bp-down(medium) {
          width: 100%;
          display: block;

          .d-lg-none {
            display: block;
          }
        }
      }
    }
  }
}

// .c-course__readmore {
//   span.c-readmore:hover {
//     span {
//       color: #ffffff;
//     }

//     svg.c-icon.c-icon--caret-right {
//       fill: #ffffff;
//     }
//   }
// }
