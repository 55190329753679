/* stylelint-disable max-nesting-depth */

.c-courses-filter__fields {
  position: relative;
  align-items: stretch;

  // @include bp(msmall) {
  //   padding-right: 115px;
  // }

  @include bp(large) {
    padding-right: 115px;
  }

  .select2-container {
    &,
    .selection,
    .select2-selection {
      height: 100%;
    }
  }
}

.c-courses-filter {
  padding-top: 25px;
  padding-bottom: 15px;
  background-color: $color-blue;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);

  .c-form__group {
    position: relative;

    @include bp(large) {
      padding-right: 0;
    }

    &--4 {
      @include bp-between(msmall, large) {
        &:nth-child(odd) {
          padding-right: 5px;
        }

        &:nth-child(even) {
          padding-left: 5px;
        }
      }
    }

    @include bp-down(msmall) {
      text-align: center;
      margin-bottom: 5px;
    }
  }
}

// .c-courses-filter__inner {
//   &.is-fadein {
//     visibility: hidden;
//   }
// }

.c-courses-filter__heading {
  color: $color-white;
  margin: 0 0 10px;
  font-weight: 400;
  font-family: $font-body;
}

.c-courses-filter__submit-container {
  position: absolute;
  right: 15px;
  bottom: 1.15rem;

  @include bp-down(large) {
    position: relative;
    right: auto;
    top: auto;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    margin-top: 1rem;
  }

  @include bp-down(medium) {
    padding: 0 30px;
  }
}

.c-courses-filter__submit {
  // position: absolute;
  // right: 15px;
  // bottom: 1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: $color-yellow;
  height: 44px;
  width: 84px;
  border-radius: 0;
  border: 1px solid $color-yellow;
  z-index: 2;

  @include bp-down(large) {
    width: 100%;
  }

  // @include bp-down(medium) {
  //   right: 30px;
  // }

  // @include bp-down(msmall) {
  //   width: 100%;
  // }

  // @include bp-down(msmall) {
  //   position: relative;
  //   right: auto;
  //   top: auto;
  //   display: block;
  //   width: 100%;
  //   max-width: 312px;
  //   margin: 0 auto;
  //   margin-top: 1rem;
  // }

  &:hover,
  &:focus {
    background-color: $color-white;
    border-color: $color-white;

    .c-courses-filter__icon {
      fill: $color-white;
    }
  }
}

.c-courses-filter__icon {
  display: block;
  width: 20px;
  height: 20px;
  fill: #8393a7;
  transition: $global-transition;

  @include bp-down(msmall) {
    margin: 0 auto;
  }
}

.select2-selection__choice-count {
  position: relative;
  top: 4px;
  padding: 0 7px;
  line-height: 36px;
}

.c-courses-filter__selections {
  margin: 10px 0 0 15px;
  color: $color-navy;
  text-transform: capitalize;

  h5 {
    color: $color-white;
  }

  strong {
    color: $color-yellow;
    font-weight: 700;
  }

  span {
    font-size: 13px;
    display: inline-block;
    padding: 1px 5px;
    border-radius: 3px;
    background-color: $color-white;
  }

  ul {
    margin: 0;
    list-style-type: none;
  }

  .c-courses-filter--clear {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
    color: $color-dark-navy;
    font-size: 18px;
    line-height: 10px;
    cursor: pointer;
  }
}
