/* ==========================================================================
   #LISTS
   ========================================================================== */

li {
  position: relative;
  line-height: 1.35;
  margin-bottom: 0.3rem;

  .u-list-check &:not(.gfield) {
    margin-bottom: 0.5rem;
    padding-left: 25px;

    &:before {
      position: absolute;
      left: 0;
      top: 2px;
      display: inline-block;
      content: '';
      width: 17px;
      height: 17px;
      background-image: url('../assets/images/check_circle.svg');
      background-size: 100%;
      background-position: left center;
    }
  }

  .u-list-bullet &:not(.gfield) {
    margin-bottom: 0.5rem;
    padding-left: 20px;

    &:before {
      position: absolute;
      left: 0;
      top: 7px;
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-green;
    }
  }

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
