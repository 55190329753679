/* stylelint-disable */

#cookie-law-info-bar {
  padding: 10px 0;
  font-size: 14px;
  line-height: 18px;
  background-color: rgba(0,0,0,0.5) !important;
  box-shadow: none;

  @include bp(large) {
    padding: 18px 0;
    text-align: left;

  }

  span {
    display: flex;
    flex-flow: wrap;
    grid-gap: 20px;
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    justify-content: space-between;
    text-align: left;

    @include bp(large) {
      // display: block;
      max-width: $container-width-large;
    }

    @include bp(mlarge) {
      max-width: $container-width-max;
    }
    p {
      margin-bottom: 0;
      max-width: 81%;
      font-weight: 500;
      letter-spacing: 0.095em;
      a {
        text-decoration: none;
      }
      @include bp-down(medium) {
        max-width: 100%;
        text-align: center;
      }
    }
    .cli-plugin-button {
      @include bp-down(medium) {
        margin: auto;
      }
    }
  }
}
