/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  transition: $global-transition;

  &:hover {
    color: $color-hover;
  }

  .c-content-section--text-white & {
    color: $color-yellow;

    &:hover {
      color: $color-white;
    }
  }
}
