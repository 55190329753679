.tml,
.tml-dashboard {
  padding: 30px 15px;
}

.tml-field-wrap {
  margin-bottom: 15px;
}

.tml-links,
.tml-dashboard-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
