/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: $font-headings;
  font-weight: 600;
  line-height: 1.5;
  color: $color-dark-navy;
}

h1, .h1 {
  //font-size: 2.25rem; // 36px
  font-size: 2.25rem;
  line-height: 1.15;

  @include bp(small) {
    //font-size: 3rem; // 48px
    font-size: 2.5rem; // 40px
  }

  // @include bp(medium) {
  //   font-size: 3.625rem; // 58px
  // }
}

h2, .h2,
.c-page-content__inner h1,
.c-hero__content-text h1 {
  //font-size: 2.125rem; // 34px
  font-size: 2.25rem;
  line-height: 1.2;

  @include bp(small) {
    font-size: 2.5rem; // 40px
  }

  // @include bp(medium) {
  //   font-size: 2.625rem; // 42px
  // }
}

h3, .h3 {
  //font-size: 1.5rem; // 24px
  // font-size: 2.25rem; // 36px
  line-height: 1.2;
  font-size: 1.875rem;

  // .c-page-content &:not(.c-testimonials__heading) {
  //   font-size: 1.875rem;
  // }
}

h4, .h4 {
  font-size: 1.5rem; // 24px
  line-height: 1.25;
}

h5, .h5 {
  font-size: 1rem; // 16px
}

h6, .h6 {
  font-size: 0.9375rem; // 15px
}
